import logo from "../../assets/images/logo.png";
import styled from "styled-components";
import Label from "../../components/atoms/label";
import Button from "../../components/atoms/button";
import {useCallback, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {scoreData} from "../../constants/scoreData";
import {quizData} from "../../constants/quizData";
import html2canvas from "html2canvas";
import jspdf from "jspdf";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import getDateFormat from "../../utils/getDateFormat";
import {insertReportRequestAction} from "../../redux/report/reducer";
import {setUserInfoRequestAction} from "../../redux/user/reducer";

const colorTableSet = [
    {
        category: 'vocabulary',
        color: '#FD8DAF40'
    },
    {
        category: 'speaking',
        color: '#F2C94C40'
    },
    {
        category: 'reading',
        color: '#6FCF9740'
    },
    {
        category: 'sentence & grammar',
        color: '#56CCF240'
    },
    {
        category: 'sentence',
        color: '#56CCF240'
    },
    {
        category: 'grammar',
        color: '#F2C94C40'
    },
]

export const Result = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const info = useSelector(((state) => state.user.info), shallowEqual);
    const [subject, setSubject] = useState(location.state.subject);
    const [choose, setChoose] = useState(location.state.choose);
    const [answer, setAnswer] = useState(quizData.find(i => i.subject === subject).answer);
    const [resultTableData, setResultTableData] = useState([]);

    useEffect(() => {
        if (!info.name) dispatch(setUserInfoRequestAction(JSON.parse(localStorage.getItem('info'))));
    }, [info]);

    const [score, setScore] = useState({
            vocabulary: {
                score: 0,
                count: 0,
                total: 0,
            },
            speaking: {
                score: 0,
                count: 0,
                total: 0,
            },
            reading: {
                score: 0,
                count: 0,
                total: 0,
            },
            sentence: {
                score: 0,
                count: 0,
                total: 0,
            },
            grammar: {
                score: 0,
                count: 0,
                total: 0,
            },
            overall: {
                score: 0,
                count: 0,
                total: 0,
            }
        }
    );

    const onPrint = useCallback(() => {
        window.print();
    }, []);

    const onSave = useCallback(() => {
        onDownPdf();
    }, []);

    const checkScore = useCallback(() => {
        const tempScore = score;

        const scoreItem = scoreData.find(i => i.subject === subject);
        const quiz = quizData.find(i => i.subject === subject);

        tempScore['vocabulary']['total'] = quiz.data.filter(i => i.category === 'Vocabulary').length;
        tempScore['speaking']['total'] = quiz.data.filter(i => i.category === 'Speaking').length;
        tempScore['reading']['total'] = quiz.data.filter(i => i.category === 'Reading').length;
        tempScore['sentence']['total'] = quiz.data.filter(i => i.category === 'Sentence').length;
        tempScore['grammar']['total'] = quiz.data.filter(i => i.category === 'Grammar').length;
        tempScore['overall']['total'] = quiz.data.length;

        for (let i of Object.keys(choose)) {
            if (answer[i - 1].trim() === choose[i].trim()) {
                tempScore[quiz.data[i - 1].category.toLowerCase()]['score'] += scoreItem[quiz.data[i - 1].category.toLowerCase()];
                tempScore[quiz.data[i - 1].category.toLowerCase()]['count'] += 1;

                tempScore['overall']['score'] += scoreItem[quiz.data[i - 1].category.toLowerCase()];
                tempScore['overall']['count'] += 1;
            }
        }

        setScore((prev) => ({...tempScore}));
    }, [answer, choose]);

    useEffect(() => {
        checkScore();
    }, [answer, choose]);

    const onChartProgress = useCallback((category, color) => {
        const result = [];

        if ((subject !== 'intermediate' && subject !== 'advanced')) {
            if (category === 'sentence & grammar') {
                score['sentence'].count += score['grammar'].count;
                score['sentence'].total += score['grammar'].total;

                category = 'sentence';
            }
        }

        if (!score[category].total) result.push(<ChartProgressBar active={false} color={color}/>);

        for (let i = 0; i < score[category].total; i++) {
            result.push(<ChartProgressBar active={i < score[category]?.count} color={color} key={i}/>);
        }

        result.push(<ChartProgressTextItem>{score[category].count}/{score[category].total}</ChartProgressTextItem>);

        return result;
    }, [score]);

    useEffect(() => {
        const temp = [];
        const quiz = quizData.find(i => i.subject === subject);

        for (let i of Object.keys(choose)) {
            let category = quiz.data[i - 1].category.toLowerCase();
            category = (subject !== 'intermediate' && subject !== 'advanced' && category === 'grammar') ? category = 'sentence' : category;

            let color = colorTableSet.find(j => j.category === category).color;

            if ((subject === 'intermediate' || subject === 'advanced')) {
                if (category === 'sentence') color = '#F2C94C40';
                if (category === 'grammar') color = '#56CCF240';
            }

            temp.push({
                answer: answer[i - 1],
                choose: choose[i],
                color
            });
        }

        setResultTableData(temp);
    }, [answer, choose]);

    useEffect(() => {
        dispatch(insertReportRequestAction({
            name: info.name,
            school: info.school,
            grade: info.grade,
            birth: `${info.birth1}.${info.birth2}.${info.birth3}`,
            level: subject,
            choose: JSON.stringify(choose),
            score: score.overall.score,
        }));
    }, [info, subject, choose]);

    const onIndicates = useCallback(() => {
        switch (subject) {
            case 'beginner':
                if (score.overall.score < 51) {
                    return 'Phonics Final'
                } else if (score.overall.score < 86) {
                    return 'Starter'
                } else {
                    return 'Starter+'
                }
            case 'starter':
                if (score.overall.score < 61) {
                    return 'Starter'
                } else if (score.overall.score < 81) {
                    return 'Starter+'
                } else {
                    return 'Basic'
                }
            case 'basic':
                if (score.overall.score < 71) {
                    return 'Basic'
                } else if (score.overall.score < 91) {
                    return 'Basic+'
                } else {
                    return 'Intermediate'
                }
            case 'intermediate':
                if (score.overall.score < 86) {
                    return 'Intermediate';
                } else {
                    return 'Advanced';
                }
            case 'advanced':
                if (score.overall.score < 91) {
                    return 'Advanced';
                } else {
                    return 'Master';
                }
        }
    }, [subject, score]);

    const onDownPdf = async () => {
        const el = document.querySelector('#wrap');
        const canvas = await html2canvas(el);
        const imageFile = canvas.toDataURL('image/png');
        const doc = new jspdf('p', 'mm', 'a4');
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        const widthRatio = pageWidth / canvas.width;
        const customHeight = canvas.height * widthRatio;

        doc.addImage(imageFile, 'png', 0, 0, pageWidth, customHeight);

        let heightLeft = customHeight;
        let heightAdd = -pageHeight;

        while (heightLeft >= pageHeight) {
            doc.addPage();
            doc.addImage(imageFile, 'png', 0, heightAdd, pageWidth, customHeight);
            heightLeft -= pageHeight;
            heightAdd -= pageHeight;
        }

        const blob = doc.output("blob");
        saveData(blob);
        // doc.save('Jennifer TEST Report' + new Date().getTime() + '.pdf');
    }

    const saveData = (blob) => {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.target = '_blank';
        a.click();
        window.URL.revokeObjectURL(url);
    };

    return (
        <ResultWrap id={'wrap'}>
            <img src={logo} alt={'logo'}/>

            <ResultHeaderWrap>
                <ResultTitle>
                    Jennifer Academy {location.state.subject.toUpperCase()} TEST Report
                </ResultTitle>

                <ResultHeaderContentWrap>
                    <ResultDesc>
                        <ul>
                            <li>Date</li>
                            <li>Name</li>
                            <li>School/Grade</li>
                            <li>Birth</li>
                        </ul>

                        <ul>
                            <li>{getDateFormat.toStringByFormatting(new Date())}</li>
                            <li>{info.name}</li>
                            <li>{info.school}/{info.grade}</li>
                            <li>{info.birth1}.{info.birth2}.{info.birth3}</li>
                        </ul>
                    </ResultDesc>

                    <ResultInfo>
                        Your Score of <strong> {score.overall.score} </strong> indicates:
                        <br/>
                        Ready for <strong>{onIndicates()}</strong>
                    </ResultInfo>
                </ResultHeaderContentWrap>
            </ResultHeaderWrap>

            <ScoreWrap>
                <Label>Score By Category</Label>

                <ScoreItemWrap>
                    <ScoreItem order={1}>
                        <ScoreCircle color={'#FD8DAF'}>{score.vocabulary.score}</ScoreCircle>
                        <span>Vocabulary</span>
                    </ScoreItem>

                    {
                        score.speaking.total ?
                            <ScoreItem order={2}>
                                <ScoreCircle color={'#F2C94C'}>{score.speaking.score}</ScoreCircle>
                                <span>Speaking</span>
                            </ScoreItem> : ''
                    }

                    {
                        score.reading.total ?
                            <ScoreItem order={(subject === 'intermediate' || subject === 'advanced') ? 3 : 2}>
                                <ScoreCircle color={'#6FCF97'}>{score.reading.score}</ScoreCircle>
                                <span>Reading</span>
                            </ScoreItem> : ''
                    }

                    {
                        (subject !== 'intermediate' && subject !== 'advanced') ?
                            <ScoreItem order={3}>
                                <ScoreCircle color={'#56CCF2'}>{score.sentence.score + score.grammar.score}</ScoreCircle>
                                <span>Sentence & Grammar</span>
                            </ScoreItem> :
                            <>
                                <ScoreItem order={(subject === 'intermediate' || subject === 'advanced') ? 2 : 3}>
                                    <ScoreCircle color={'#F2C94C'}>{score.sentence.score}</ScoreCircle>
                                    <span>Sentence</span>
                                </ScoreItem>

                                <ScoreItem order={4}>
                                    <ScoreCircle color={'#56CCF2'}>{score.grammar.score}</ScoreCircle>
                                    <span>Grammar</span>
                                </ScoreItem>
                            </>
                    }

                    <ScoreItem order={5}>
                        <ScoreCircle color={'#BB6BD9'}>{score.overall.score}</ScoreCircle>
                        <span>Overall Score</span>
                    </ScoreItem>
                </ScoreItemWrap>
            </ScoreWrap>

            <ChartProgressWrap>
                <ChartProgressHeader>
                    <ChartProgressHeaderItem order={1}>Vocabulary</ChartProgressHeaderItem>
                    {
                        score.speaking.total ? <ChartProgressHeaderItem order={2}>Speaking</ChartProgressHeaderItem> : ''
                    }

                    {
                        score.reading.total ? <ChartProgressHeaderItem order={(subject === 'intermediate' || subject === 'advanced') ? 3 : 2}>Reading</ChartProgressHeaderItem> : ''
                    }
                    {
                        (subject !== 'intermediate' && subject !== 'advanced') ?
                            <ChartProgressHeaderItem order={3}>Sentence & Grammar</ChartProgressHeaderItem> :
                            <>
                                <ChartProgressHeaderItem order={(subject === 'intermediate' || subject === 'advanced') ? 2 : 3}>Sentence</ChartProgressHeaderItem>
                                <ChartProgressHeaderItem order={4}>Grammar</ChartProgressHeaderItem>
                            </>
                    }
                    <ChartProgressHeaderItem order={5}>Overall Score</ChartProgressHeaderItem>
                </ChartProgressHeader>

                <ChartProgressItemWrap>
                    <ChartProgressItem order={1}>
                        {
                            onChartProgress('vocabulary', '#FD8DAF')
                        }
                    </ChartProgressItem>

                    {
                        score.speaking.total ?
                            <ChartProgressItem order={2}>
                                {
                                    onChartProgress('speaking', '#F2C94C')
                                }
                            </ChartProgressItem> : ''
                    }

                    {
                        score.reading.total ?
                            <ChartProgressItem order={(subject === 'intermediate' || subject === 'advanced') ? 3 : 2}>
                                {
                                    onChartProgress('reading', '#6FCF97')
                                }
                            </ChartProgressItem> : ''
                    }

                    {
                        (subject !== 'intermediate' && subject !== 'advanced') ?
                            <ChartProgressItem order={3}>
                                {
                                    onChartProgress('sentence & grammar', '#56CCF2')
                                }
                            </ChartProgressItem> :
                            <>
                                <ChartProgressItem order={(subject === 'intermediate' || subject === 'advanced') ? 2 : 3}>
                                    {
                                        onChartProgress('sentence', '#F2C94C')
                                    }
                                </ChartProgressItem>
                                <ChartProgressItem order={4}>
                                    {
                                        onChartProgress('grammar', '#56CCF2')
                                    }
                                </ChartProgressItem>
                            </>
                    }

                    <ChartProgressItem order={5}>
                        {
                            onChartProgress('overall', '#BB6BD9')
                        }
                    </ChartProgressItem>
                </ChartProgressItemWrap>
            </ChartProgressWrap>

            <TableWrap>
                <TableHeader>
                    <span>No</span>
                    <span>Answer</span>
                    <span>Student</span>
                    <span></span>
                </TableHeader>

                {
                    resultTableData.map((i, index) => (
                        <TableItem color={i.color} key={index}>
                            <span>{index + 1}</span>
                            <span>{i.answer}</span>
                            <span>{i.choose}</span>
                            <span>{i.answer.trim() === i.choose.trim() ? 'O' : 'X'}</span>
                        </TableItem>
                    ))
                }
            </TableWrap>

            <NavWrap>
                <Button color={'white'} shape={'rectangle'} onClick={onPrint}> PRINT </Button>
                <Button color={'main'} shape={'rectangle'} onClick={onSave}> SAVE </Button>
            </NavWrap>
        </ResultWrap>
    );
}

const ResultWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  max-width: 1240px;
  padding: 20px 20px 0 20px;
  margin: 0 auto;

  & > img {
    width: 120px;
  }

  & * {
    -webkit-print-color-adjust: exact;
  }

  @page {
    size: A3;
    margin: 0;
  }
`

const ResultHeaderWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 50px;
  padding-bottom: 80px;
  border-bottom: 1px solid #eee;
`

const ResultTitle = styled.div`
  background-color: #000;
  color: #fff;
  border-radius: 12px;
  padding: 8px 0;
  font-size: 40px;
  font-weight: bold;
  text-align: center;
`

const ResultHeaderContentWrap = styled.div`
  display: flex;
  gap: 100px;
  align-items: center;
  justify-content: center;
`

const ResultDesc = styled.div`
  display: flex;
  gap: 15px;
  line-height: 40px;
  font-size: 28px;

  & > ul:first-child {
    font-weight: bold;
  }

  & > ul:last-child {
    text-align: end;
  }
`

const ResultInfo = styled.div`
  font-size: 36px;
  padding: 20px;
  box-shadow: rgb(0 0 0 / 15%) 0 0 6px 0;
  border-radius: 12px;

  & > strong {
    color: #D65CAD;
  }
`

const ScoreWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  font-size: 40px;
  font-weight: bold;
`

const ScoreItemWrap = styled.div`
  display: flex;
  gap: 40px;
  justify-content: center;
`

const ScoreItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  font-size: 24px;
  font-weight: 500;
  order: ${({order}) => order};
`

const ScoreCircle = styled.div`
  width: 170px;
  height: 170px;
  background-color: ${({color}) => color};
  border-radius: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 56px;
`

const ChartProgressHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  white-space: nowrap;
`

const ChartProgressHeaderItem = styled.span`
  order: ${({order}) => order};
`

const ChartProgressWrap = styled.div`
  display: flex;
  font-size: 24px;
  gap: 50px;
  width: 100%;
  margin-top: 50px;
`

const ChartProgressTextItem = styled.div`
  position: absolute;
  right: 10px;
  font-size: 13px;
  font-weight: bold;
  height: 100%;
  display: flex;
  align-items: center;
`

const ChartProgressItem = styled.div`
  display: flex;
  position: relative;
  order: ${({order}) => order};
`

const ChartProgressItemWrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 30px;
  justify-content: space-between;
`

const ChartProgressBar = styled.div`
  height: 26px;
  width: 100%;
  background-color: ${({active, color}) => active ? color : '#eee'};
`;

const TableWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 50px;
`

const TableHeader = styled.div`
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  text-align: center;
  font-size: 28px;
  font-weight: bold;

  & > span {
    flex: 1;
    white-space: nowrap;
  }

  & > span:first-child {
    flex: initial;
    width: 5%;
  }

  & > span:last-child {
    flex: initial;
    width: 5%;
  }
`

const TableItem = styled.div`
  background-color: ${({color}) => color};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #999;
  text-align: center;
  font-size: 24px;
  gap: 10px;
  word-break: break-word;

  & > span {
    width: 45%;
    white-space: normal;
  }

  & > span:first-child {
    flex: initial;
    width: 5%;
  }

  & > span:last-child {
    flex: initial;
    width: 5%;
  }
`

const NavWrap = styled.div`
  display: flex;
  width: 100%;
  padding: 30px 0;
  border-top: 1px solid #eee;
  align-items: center;
  justify-content: center;
  font-size: 21px;
  font-weight: bold;
  gap: 30px;
  margin-top: 50px;

  & > button {
    width: 500px;
  }
`

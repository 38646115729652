import styled from "styled-components";
import Button from "../button";
import {useDispatch, useSelector} from "react-redux";
import {showAlertRequestAction} from "../../../redux/alert/reducer";
import {useCallback} from "react";

const Alert = () => {
    const {showAlert, onSubmit, title, component} = useSelector((state) => state.alert);
    const dispatch = useDispatch();
    const onClose = useCallback(() => {
        dispatch(showAlertRequestAction({showAlert: false}));
    }, []);

    return (
        <Wrap className={showAlert && 'show'}>
            <WrapInner>
                <ModalTopWrap>
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                        <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                        <path d="M2 8c0-2.2.7-4.3 2-6"></path>
                        <path d="M22 8a10 10 0 0 0-2-6"></path>
                    </svg>
                </ModalTopWrap>

                <ModalTitleWrap>
                    {title}
                </ModalTitleWrap>

                {
                    component && component
                }

                <ModalButtonWrap>
                    <Button shape={'rectangle'} size={'xs'} onClick={onClose}>취소</Button>
                    <Button shape={'rectangle'} size={'xs'} color={'navy'} onClick={onSubmit}>확인</Button>
                </ModalButtonWrap>
            </WrapInner>
        </Wrap>
    )
}

const Wrap = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: visibility 0s linear 0.2s, opacity 0.2s 0s;
  visibility: hidden;
  opacity: 0;
  
  &.show {
    transition: visibility 0s ease-in-out 0s, opacity 0.4s ease-in-out 0s;
    visibility: visible;
    opacity: 1; 
  }

  &.show > div {
    margin-top: 30vh;
  }
  
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: -1;
    background-color: rgb(0, 0, 0, 0.3);
  }
`

const WrapInner = styled.div`
  width: 300px;
  margin: 50vh auto auto auto;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: rgb(0 0 0 / 15%) 0 0 6px 0;
  padding: 30px 20px 20px 20px;
  display: flex;
  gap: 30px;
  flex-direction: column;
  justify-content: space-between;
  transition: margin-top 0.4s;
`

const ModalTopWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalTitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalButtonWrap = styled.div`
  display: flex;
  gap: 20px;
`

export default Alert;

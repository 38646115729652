export const quizData = [
    {
        subject: 'preTestA',
        answer: [
            "pull",
            "I'm 10.",
            "What are they?",
            "No, I can't.",
            "Where's the ball?",
            "I was at the park.",
            "They are not sad",
            "Is he a teacher ?",
            "many",
            "bike"
        ],
        data: [
            {
                type: 1, // 가로
                number: 1,
                category: 'Vocabulary',
                title: '1. 다음 단어의 반대말을 찾으시오.',
                question: 'push',
                answer: ['open', 'pull', 'close']
            },
            {
                type: 2, // 세로
                number: 2,
                category: 'Speaking',
                title: '2. 문장을 읽고 질문 또는 대답을 고르세요.',
                question: 'How old are you?',
                answer: ["I'm Judy.", "I'm 10."]
            },
            {
                type: 2, // 세로
                number: 3,
                category: 'Speaking',
                title: '3. 문장을 읽고 질문 또는 대답을 고르세요.',
                question: "A : {blank} ? <br/> B : They're strawberries.",
                answer: ["What are they?", "Where are they?"]
            },
            {
                type: 2, // 세로
                number: 4,
                category: 'Speaking',
                title: '4. 문장을 읽고 질문 또는 대답을 고르세요.',
                question: "Can you sing a song?",
                answer: ["Yes, you are.", "No, I can't."]
            },
            {
                type: 2, // 세로
                number: 5,
                category: 'Speaking',
                title: '5. 문장을 읽고 질문 또는 대답을 고르세요.',
                question: "A : {blank} ? <br/> B : It's under the table.",
                answer: ["What is it?", "Where's the ball?"]
            },
            {
                type: 2, // 세로
                number: 6,
                category: 'Speaking',
                title: '6. 문장을 읽고 질문 또는 대답을 고르세요.',
                question: "Where were you yesterday?",
                answer: ["I was at the park.", "It was sunny and warm."]
            },
            {
                type: 3, // drag
                number: 7,
                category: 'Sentence',
                title: '7. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)',
                question: "그들은 슬프지 않다.",
                answer: ["not", "They", "sad", "are"]
            },
            {
                type: 3, // drag
                number: 8,
                category: 'Sentence',
                title: '8. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)',
                question: "그는 선생님이니?",
                answer: ["teacher", "a", "?", "he", "Is"]
            },
            {
                type: 1, // 가로
                number: 9,
                category: 'Sentence',
                title: '9. 빈칸에 알맞을 말을 고르세요.',
                question: "I have {blank} apples.",
                answer: ["a", "an", "many", "are"]
            },
            {
                type: 4, // 문장
                number: 10,
                category: 'Reading',
                title: '10. What can they ride?',
                question: "There is a book. They can read. <br/> There is a bike. They can ride. <br/> What a good day!",
                answer: ["bike", "toy", "book"]
            },
        ]
    },
    {
        subject: 'preTestB',
        answer: [
            "I wanted to be a doctor",
            "Flowers are more beautiful than birds",
            "If you eat carrots , she will hug you",
            "threw",
            "that",
            "are given",
            "black",
            "Donkeys need a field and a fence.",
            "It is about the similarities and differences between red foxes and gray wolves.",
            "Red foxes are better at adapting to environmental changes than wolves."
        ],
        data: [
            {
                type: 3,
                number: 1,
                category: 'Sentence',
                title: `1. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)`,
                question: '나는 의사가 되고 싶었어.',
                answer: ['a', 'be', 'I', 'to', 'wanted', 'doctor']
            },
            {
                type: 3,
                number: 2,
                category: 'Sentence',
                title: `2. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)`,
                question: '꽃들이 새들보다 더 아름답다.',
                answer: ["beautiful", "more", "are", "birds", "than", "Flowers"]
            },
            {
                type: 3,
                number: 3,
                category: 'Sentence',
                title: `3. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)`,
                question: "만약 너가 당근들을 먹는다면 그녀는 너를 안아줄 것이다.",
                answer: ["eat", "you", "she", "carrots", ",", "will", "you", "hug", "If"]
            },
            {
                type: 1,
                number: 4,
                category: 'Sentence',
                title: `4. 빈칸에 알맞을 말을 고르세요.`,
                question: "Sally {blank} a ball to the window last weekend.",
                answer: ["throws", "throw", "throwed", "threw"]
            },
            {
                type: 1,
                number: 5,
                category: 'Sentence',
                title: `5. 빈칸에 알맞을 말을 고르세요.`,
                question: "He will be the best singer {blank} everyone likes.",
                answer: ["that", "and", "who", "whom"]
            },
            {
                type: 1,
                number: 6,
                category: 'Sentence',
                title: `6. 빈칸에 알맞을 말을 고르세요.`,
                question: "People in park {blank} balloons every weekend.",
                answer: ["gives", "is given", "gave", "are given"]
            },
            {
                type: 4,
                number: 7,
                category: 'Reading',
                title: "7. What color is Tim's hair?",
                question: "Tim paints Sally. <br/> She has straight red hair and brown eyes. <br/> Sally paints Tim. <br/> He has short black hair and green eyes.",
                answer: ["red", "brown", "black"]
            },
            {
                type: 4,
                number: 8,
                category: 'Reading',
                title: "8. Why can't Jack get a donkey?",
                question: "Donkeys make great pets. They eat grass, so they are easy to feed. But Jack lives in an apartment. There isn't a field with a fence for donkey.",
                answer: ["Donkeys are hard to feed.", "Donkeys need a field and a fence.", "Donkeys eat grass."]
            },
            {
                type: 4,
                number: 9,
                category: 'Reading',
                title: `9. What is the main idea of the passage?`,
                question: "What do foxes and wolves look like? They look like dogs. This is because they are all related and are members of the wild dog family." +
                    "<br/>" +
                    "These animals live in many habitats. As people build roads and buildings in new places, both foxes and wolves have lost their homes.  But the red fox has adapted to its changed environment. many foxes now make their homes near towns." +
                    "<br/>" +
                    "Wolves keep away from people. They live in packs of four to seven, and they hunt and travel together. This helps them catch large animals for food. Foxes, however, like to live alone infields or empry holes. They prefer to hunt for small animals alone." +
                    "<br/>" +
                    "Both wolves and foxes have excellent hearing and communicate the same way. They bark and growl.",
                answer: ["It is about all the members of the wild dog family.", "It is about the things red foxes and grey wolves have in common.", "It is about the similarities and differences between red foxes and gray wolves."],
                isLong: true,
            },
            {
                type: 4,
                number: 10,
                category: 'Reading',
                title: `10. What can be inferred from the passage?`,
                question: "What do foxes and wolves look like? They look like dogs. This is because they are all related and are members of the wild dog family." +
                    "<br/>" +
                    "These animals live in many habitats. As people build roads and buildings in new places, both foxes and wolves have lost their homes.  But the red fox has adapted to its changed environment. many foxes now make their homes near towns." +
                    "<br/>" +
                    "Wolves keep away from people. They live in packs of four to seven, and they hunt and travel together. This helps them catch large animals for food. Foxes, however, like to live alone infields or empry holes. They prefer to hunt for small animals alone." +
                    "<br/>" +
                    "Both wolves and foxes have excellent hearing and communicate the same way. They bark and growl.",
                answer: ["People build new places where foxes and wolves do not live.", "Dogs could act like gray wolves if they ran into a pack of wolves.", "Red foxes are better at adapting to environmental changes than wolves."],
                isLong: true,
            },
        ]
    },
    {
        subject: 'beginner',
        answer: [
            "풍선",
            "사탕",
            "원숭이",
            "shape",
            "pencil",
            "베개",
            "새",
            "door",
            "rainbow",
            "close",
            "I'm Eddie.",
            "What are they?",
            "I'm fine.",
            "It's blue.",
            "What is it?",
            "No, it isn't.",
            "I'm 7.",
            "Do you like fish?",
            "I can touch my eyes.",
            "No, I can't.",
            "They're CDs.",
            "Where's the bat?",
            "No thank you!",
            "It's six o'clock.",
            "I was at the beach.",
            "I am a boy",
            "They are not happy",
            "Is she a teacher ?",
            "an",
            "is",
        ],
        data: [
            {
                type: 1,
                number: 1,
                category: 'Vocabulary',
                title: '1. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: 'Balloon',
                answer: ['풍선', '자전거', '새']
            },
            {
                type: 1,
                number: 2,
                category: 'Vocabulary',
                title: '2. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: 'Candy',
                answer: ['초콜릿', '사탕', '카라멜']
            },
            {
                type: 1,
                number: 3,
                category: 'Vocabulary',
                title: '3. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: 'Monkey',
                answer: ['원숭이', '다람쥐', '코끼리']
            },
            {
                type: 1,
                number: 4,
                category: 'Vocabulary',
                title: '4. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: '모양',
                answer: ['uncle', 'shape', 'woman']
            },
            {
                type: 1,
                number: 5,
                category: 'Vocabulary',
                title: '5. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: '연필',
                answer: ['eraser', 'pencil', 'notebook']
            },
            {
                type: 1,
                number: 6,
                category: 'Vocabulary',
                title: '6. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: 'pillow',
                answer: ['베개', '침대', '침실']
            },
            {
                type: 1,
                number: 7,
                category: 'Vocabulary',
                title: '7. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: 'bird',
                answer: ['새', '벌', '노래하다']
            },
            {
                type: 1,
                number: 8,
                category: 'Vocabulary',
                title: '8. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: '문',
                answer: ['door', 'house', 'neighbor']
            },
            {
                type: 1,
                number: 9,
                category: 'Vocabulary',
                title: '9. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: '무지개',
                answer: ['bat', 'rainbow', 'storm']
            },
            {
                type: 1,
                number: 10,
                category: 'Vocabulary',
                title: '10. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: 'open 의 반대말',
                answer: ['push', 'pull', 'close']
            },
            {
                type: 2,
                number: 11,
                category: 'Speaking',
                title: '11. 다음 물음에 알맞은 답을 고르세요.',
                question: "What's your name?",
                answer: ["I'm Eddie.", "It's Jenny."]
            },
            {
                type: 2,
                number: 12,
                category: 'Speaking',
                title: '12. 다음 물음에 알맞은 답을 고르세요.',
                question: "A : {blank} ? <br/> B : They're eggs.",
                answer: ['What are they?', 'What is it?']
            },
            {
                type: 2,
                number: 13,
                category: 'Speaking',
                title: '13. 다음 물음에 알맞은 답을 고르세요.',
                question: 'Hi! How are you?',
                answer: ['You are happy.', "I'm fine."]
            },
            {
                type: 2,
                number: 14,
                category: 'Speaking',
                title: '14. 다음 물음에 알맞은 답을 고르세요.',
                question: 'What color is it?',
                answer: ["It's blue.", "It's a circle."]
            },
            {
                type: 2,
                number: 15,
                category: 'Speaking',
                title: '15. 다음 물음에 알맞은 답을 고르세요.',
                question: "A : {blank} ? <br/> B : It's a ball.",
                answer: ['What is it?', 'Who are you?']
            },
            {
                type: 2,
                number: 16,
                category: 'Speaking',
                title: '16. 다음 물음에 알맞은 답을 고르세요.',
                question: 'Is it a star?',
                answer: ["No, it isn't.", 'It is a star.']
            },
            {
                type: 2,
                number: 17,
                category: 'Speaking',
                title: '17. 다음 물음에 알맞은 답을 고르세요.',
                question: 'How old are you?',
                answer: ["I'm 7.", "I'm Andy."]
            },
            {
                type: 2,
                number: 18,
                category: 'Speaking',
                title: '18. 다음 물음에 알맞은 답을 고르세요.',
                question: "A : {blank} <br/> B : No, I don't.",
                answer: ['Do you like fish?', 'Are you a boy?']
            },
            {
                type: 2,
                number: 19,
                category: 'Speaking',
                title: '19. 다음 물음에 알맞은 답을 고르세요.',
                question: 'What can you do?',
                answer: ['I can touch my eyes.', 'Yes, I can.']
            },
            {
                type: 2,
                number: 20,
                category: 'Speaking',
                title: '20. 다음 물음에 알맞은 답을 고르세요.',
                question: 'Can you dance?',
                answer: ['Yes, you are.', "No, I can't."]
            },
            {
                type: 2,
                number: 21,
                category: 'Speaking',
                title: '21. 다음 물음에 알맞은 답을 고르세요.',
                question: 'What are these?',
                answer: ["It's a cellphone.", "They're CDs."]
            },
            {
                type: 2,
                number: 22,
                category: 'Speaking',
                title: '22. 다음 물음에 알맞은 답을 고르세요.',
                question: "A : {blank} ? <br/> B : It's on the table.",
                answer: ["Where's the bat?", 'What is it?']
            },
            {
                type: 2,
                number: 23,
                category: 'Speaking',
                title: '23. 다음 물음에 알맞은 답을 고르세요.',
                question: 'Do you want spaghetti?',
                answer: ['No thank you!', 'I like spaghetti.']
            },
            {
                type: 2,
                number: 24,
                category: 'Speaking',
                title: '24. 다음 물음에 알맞은 답을 고르세요.',
                question: 'What time is it?',
                answer: ["It's six o'clock.", "It's time for dinner."]
            },
            {
                type: 2,
                number: 25,
                category: 'Speaking',
                title: '25. 다음 물음에 알맞은 답을 고르세요.',
                question: 'Where were you yesterday?',
                answer: ['It was sunny and warm.', 'I was at the beach.']
            },
            {
                type: 3,
                number: 26,
                category: 'Sentence',
                title: '26. 다음 물음에 알맞은 답을 고르세요.',
                question: '나는 소년이다.',
                answer: ['am', 'a', 'I', 'boy']
            },
            {
                type: 3,
                number: 27,
                category: 'Sentence',
                title: '27. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)',
                question: '그들은 행복하지 않다.',
                answer: ['not', 'They', 'happy', 'are']
            },
            {
                type: 3,
                number: 28,
                category: 'Sentence',
                title: '28. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)',
                question: '그녀는 선생님이니?',
                answer: ['teacher', 'a', '?', 'Is', 'she']
            },
            {
                type: 1,
                number: 29,
                category: 'Grammar',
                title: '29. 빈칸에 알맞은 말을 고르세요.',
                question: 'I have {blank} apple.',
                answer: ['a', 'an', 'many', 'are']
            },
            {
                type: 1,
                number: 30,
                category: 'Grammar',
                title: '30. 빈칸에 알맞은 말을 고르세요.',
                question: 'Your car {blank} very clean.',
                answer: ['a', 'are', 'is', 'has']
            },
        ]
    },
    {
        subject: 'starter',
        answer: [
            "풍선",
            "사탕",
            "원숭이",
            "shape",
            "pencil",
            "베개",
            "새",
            "door",
            "rainbow",
            "close",
            "They're CDs.",
            "Where's the bat?",
            "No thank you!",
            "It's six o'clock.",
            "I was at the beach.",
            "I want to be a singer.",
            "It's the 1st.",
            "I'm making for someone.",
            "I will stay home.",
            "A cat is as fast as a polar bear.",
            "Tim",
            "Book",
            "Seeds",
            "Root",
            "by bus",
            "red",
            "They dig",
            "use her beak",
            "Grasshopper",
            "They can help her",
            "I am a boy",
            "They are not happy",
            "Is she a teacher ?",
            "Do you drive a bus ?",
            "I am not singing",
            "What is she doing ?",
            "an",
            "is",
            "went",
            "doesn't eat",
        ],
        data: [
            {
                type: 1,
                number: 1,
                category: 'Vocabulary',
                title: '1. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: 'Balloon',
                answer: ['풍선', '자전거', '새']
            },
            {
                type: 1,
                number: 2,
                category: 'Vocabulary',
                title: '2. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: 'Candy',
                answer: ['초콜릿', '사탕', '카라멜']
            },
            {
                type: 1,
                number: 3,
                category: 'Vocabulary',
                title: '3. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: 'Monkey',
                answer: ['원숭이', '다람쥐', '코끼리']
            },
            {
                type: 1,
                number: 4,
                category: 'Vocabulary',
                title: '4. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: '모양',
                answer: ['uncle', 'shape', 'woman']
            },
            {
                type: 1,
                number: 5,
                category: 'Vocabulary',
                title: '5. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: '연필',
                answer: ['eraser', 'pencil', 'notebook']
            },
            {
                type: 1,
                number: 6,
                category: 'Vocabulary',
                title: '6. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: 'pillow',
                answer: ['베개', '침대', '침실']
            },
            {
                type: 1,
                number: 7,
                category: 'Vocabulary',
                title: '7. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: 'bird',
                answer: ['새', '벌', '노래하다']
            },
            {
                type: 1,
                number: 8,
                category: 'Vocabulary',
                title: '8. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: '문',
                answer: ['door', 'house', 'neighbor']
            },
            {
                type: 1,
                number: 9,
                category: 'Vocabulary',
                title: '9. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: '무지개',
                answer: ['bat', 'rainbow', 'storm']
            },
            {
                type: 1,
                number: 10,
                category: 'Vocabulary',
                title: '10. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: 'open 의 반대말',
                answer: ['push', 'pull', 'close']
            },
            {
                type: 2,
                number: 11,
                category: 'Speaking',
                title: '11. 다음 물음에 알맞은 답을 고르세요.',
                question: 'What are these?',
                answer: ["It's a cellphone.", "They're CDs."]
            },
            {
                type: 2,
                number: 12,
                category: 'Speaking',
                title: '12. 다음 물음에 알맞은 답을 고르세요.',
                question: "A : {blank} ? <br/> B : It's on the table",
                answer: ["Where's the bat?", 'What is it?']
            },
            {
                type: 2,
                number: 13,
                category: 'Speaking',
                title: '13. 다음 물음에 알맞은 답을 고르세요.',
                question: 'Do you want spaghetti?',
                answer: ['No thank you!', 'I like spaghetti.']
            },
            {
                type: 2,
                number: 14,
                category: 'Speaking',
                title: '14. 다음 물음에 알맞은 답을 고르세요.',
                question: 'What time is it?',
                answer: ["It's six o'clock.", "It's time for dinner"]
            },
            {
                type: 2,
                number: 15,
                category: 'Speaking',
                title: '15. 다음 물음에 알맞은 답을 고르세요.',
                question: 'Where were you yesterday?',
                answer: ['It was sunny and warm', 'I was at the beach.']
            },
            {
                type: 2,
                number: 16,
                category: 'Speaking',
                title: '16. 다음 물음에 알맞은 답을 고르세요.',
                question: 'What do you want to be?',
                answer: ['I want to be a singer.', 'I want a candy.']
            },
            {
                type: 2,
                number: 17,
                category: 'Speaking',
                title: '17. 다음 물음에 알맞은 답을 고르세요.',
                question: "What's the date today?",
                answer: ["It's Friday.", "It's the 1st."]
            },
            {
                type: 2,
                number: 18,
                category: 'Speaking',
                title: '18. 다음 물음에 알맞은 답을 고르세요.',
                question: 'Can I help you?',
                answer: ["No, I can't. I'm busy.", "I'm making for someone."]
            },
            {
                type: 2,
                number: 19,
                category: 'Speaking',
                title: '19. 다음 물음에 알맞은 답을 고르세요.',
                question: 'What are you going to do this weekend?',
                answer: ["I will stay home.", "I went to the library."]
            },
            {
                type: 2,
                number: 20,
                category: 'Speaking',
                title: '20. 다음 물음에 알맞은 답을 고르세요.',
                question: 'Which animal is faster, a cat or a polar bear?',
                answer: ['A cat is as fast as a polar bear.', 'A polar bear is slow.']
            },
            {
                type: 4,
                number: 21,
                category: 'Reading',
                title: '21. Who can pour?',
                question: "Let's make cupcakes!<br/>" +
                    'Tim can pour. Jen can mix.<br/>' +
                    'Mom and Tim can bake the cupcakes.',
                answer: ['Tim', 'Jen', "Mom and Tim"]
            },
            {
                type: 4,
                number: 22,
                category: 'Reading',
                title: '22. What can they read?',
                question: 'There is a book. They can read. <br/>' +
                    'What a good day!',
                answer: ['Bike', 'Toy', "Book"]
            },
            {
                type: 4,
                number: 23,
                category: 'Reading',
                title: '23. What do they put in the garden?',
                question: 'We make a garden. We put the seeds in the garden. They get sun and water.',
                answer: ['Sun', 'Tomato', "Seeds"]
            },
            {
                type: 4,
                number: 24,
                category: 'Reading',
                title: '24. What goes down on the tree?',
                question: 'The tree branches go up and up.<br/>' +
                    ' The tree roots go down and town.<br/>' +
                    'The tree has lots of pink flowers.<br/>' +
                    'Then it has lots of green leaves.',
                answer: ['Branch', 'Root', "Flower"]
            },
            {
                type: 4,
                number: 25,
                category: 'Reading',
                title: '25. How do they go to the airport?',
                question: 'We put the boxes in a van. We go to the airport by bus.<br/>' +
                    'Then we go on an airplane. We go in a taxi to the ship.',
                answer: ['by airplane', 'by bus', "by taxi"]
            },
            {
                type: 4,
                number: 26,
                category: 'Reading',
                title: "26. What color is Sam's hair?",
                question: 'Sam paints Ted.<br/>' +
                    'Ted has straight black hair and brown eyes.<br/>' +
                    'Ted paints Sam.<br/>' +
                    'Sam has short red hair and green eyes.',
                answer: ['black', 'red', "green"]
            },
            {
                type: 4,
                number: 27,
                category: 'Reading',
                title: '27. What do the chicks do to make a garden?',
                question: 'Six chicks live on a farm. They dig and make a garden. They walk down the hill to the blueberry bushes. The chicks pick the blueberries.',
                answer: ['They dig', 'They walk down', "They pick the blueberries"]
            },
            {
                type: 4,
                number: 28,
                category: 'Reading',
                title: '28. How does the mother bird stop the snake?',
                question: 'A mother and father bird make a nest.<br/>' +
                    'The mother bird lays four eggs.<br/>' +
                    'The snake wants to eat the eggs.<br/>' +
                    'The mother bird stops it with her beak.',
                answer: ['make a nest', 'eat the eggs', "use her beak"]
            },
            {
                type: 4,
                number: 29,
                category: 'Reading',
                title: '29. What bugs can jump?',
                question: 'Five bugs are playing in a field.<br/>' +
                    'Bee and Ladybug can fly.<br/>' +
                    'Grasshopper can jump.<br/>' +
                    'Ant can run fast.<br/>' +
                    "But poor Caterpillar can't fly or jump.<br/>" +
                    "He can't run fast.",
                answer: ['Lady bug', 'Grasshopper', "Caterpillar"]
            },
            {
                type: 4,
                number: 30,
                category: 'Reading',
                title: "30. Why are Lucy's writing tools happy?",
                question: 'Lucy draws a picture. The tools are happy.<br/>' +
                    'They can help her again.<br/>' +
                    'Her teacher praises the report. Lucy is proud.',
                answer: ['Lucy draws a picture.', 'They can help her', "Her teacher praises the report."]
            },
            {
                type: 3,
                number: 31,
                category: 'Sentence',
                title: '31. 다음 물음에 알맞은 답을 고르세요.',
                question: '나는 소년이다.',
                answer: ['am', 'a', 'I', 'boy']
            },
            {
                type: 3,
                number: 32,
                category: 'Sentence',
                title: '32. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)',
                question: '그들은 행복하지 않다.',
                answer: ['not', 'They', 'happy', 'are']
            },
            {
                type: 3,
                number: 33,
                category: 'Sentence',
                title: '33. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)',
                question: '그녀는 선생님이니?',
                answer: ['teacher', 'a', '?', 'Is', 'she']
            },
            {
                type: 3,
                number: 34,
                category: 'Sentence',
                title: '34. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)',
                question: '너는 버스를 운전하니?',
                answer: ['you', 'drive', 'Do', '?', 'bus', 'a']
            },
            {
                type: 3,
                number: 35,
                category: 'Sentence',
                title: '35. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)',
                question: '나는 노래를 부르고 있지 않아.',
                answer: ['am', 'I', 'singing', 'not']
            },
            {
                type: 3,
                number: 36,
                category: 'Sentence',
                title: '36. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)',
                question: '그녀는 무엇을 하고 있나요?',
                answer: ['she', '?', 'is', 'What', 'doing']
            },
            {
                type: 1,
                number: 37,
                category: 'Grammar',
                title: '37. 빈칸에 알맞은 말을 고르세요.',
                question: 'I have {blank} apple.',
                answer: ['a', 'an', 'many', 'are']
            },
            {
                type: 1,
                number: 38,
                category: 'Grammar',
                title: '38. 빈칸에 알맞은 말을 고르세요.',
                question: 'Your car {blank} very clean.',
                answer: ['a', 'are', 'is', 'has']
            },
            {
                type: 1,
                number: 39,
                category: 'Grammar',
                title: '39. 빈칸에 알맞은 말을 고르세요.',
                question: 'Jenny {blank} to elementary school last year.',
                answer: ['go', 'goes', 'goed', 'went']
            },
            {
                type: 1,
                number: 40,
                category: 'Grammar',
                title: '40. 빈칸에 알맞은 말을 고르세요.',
                question: 'A cow {blank} meat.',
                answer: ["don't eat", "doesn't eat", "doesn't eats", 'not eat']
            },
        ]
    },
    {
        subject: 'basic',
        answer: [
            "곱슬거리는",
            "파다",
            "warm",
            "toad",
            "child",
            "얼다",
            "천둥",
            "shy",
            "add",
            "left",
            "I want to be a singer.",
            "Because it's easier than Math.",
            "It's the 1st.",
            "What did he do last summer?",
            "I'm making for someone.",
            "No, it feels rough.",
            "I will stay home.",
            "I've been there twice.",
            "A cat is as fast as a polar bear.",
            "It's across from the music store.",
            "red",
            "They dig",
            "use her beak",
            "Grasshopper",
            "They can help her",
            "He teaches him to do tricks.",
            "It takes about five months.",
            "It's because the sun spins on its axis.",
            "It is very cold. The ground and rivers freeze.",
            "It is to have peace in the world.",
            "Do you drive a bus ?",
            "I am not singing",
            "What is she doing ?",
            "Will you visit your friend ?",
            "We did not go shopping",
            "It will be a sweet orange",
            "is",
            "went",
            "doesn't eat",
            "looks strong",
        ],
        data: [
            {
                type: 1,
                number: 1,
                category: 'Vocabulary',
                title: '1. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: 'curly',
                answer: ['곱슬거리는', '짧은', '긴']
            },
            {
                type: 1,
                number: 2,
                category: 'Vocabulary',
                title: '2. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: 'dig',
                answer: ['강아지', '파다', '떨어지다']
            },
            {
                type: 1,
                number: 3,
                category: 'Vocabulary',
                title: '3. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: '따뜻한',
                answer: ['windy', 'warm', 'cool']
            },
            {
                type: 1,
                number: 4,
                category: 'Vocabulary',
                title: '4. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: '두꺼비',
                answer: ['squirrel', 'toad', 'frog']
            },
            {
                type: 1,
                number: 5,
                category: 'Vocabulary',
                title: '5. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: 'adult의 반대말',
                answer: ['fire fighter', 'police officer', 'child']
            },
            {
                type: 1,
                number: 6,
                category: 'Vocabulary',
                title: '6. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: 'freeze',
                answer: ['얼다', '두꺼운', '얇은']
            },
            {
                type: 1,
                number: 7,
                category: 'Vocabulary',
                title: '7. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: 'thunder',
                answer: ['번개', '토네이도', '천둥']
            },
            {
                type: 1,
                number: 8,
                category: 'Vocabulary',
                title: '8. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: '부끄러운',
                answer: ['patient', 'shy', 'nervous']
            },
            {
                type: 1,
                number: 9,
                category: 'Vocabulary',
                title: '9. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: '더하다',
                answer: ['invite', 'minus', 'add']
            },
            {
                type: 1,
                number: 10,
                category: 'Vocabulary',
                title: '10. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: 'right 의 반대말',
                answer: ['back', 'left', 'straight']
            },
            {
                type: 2,
                number: 11,
                category: 'Speaking',
                title: '11. 다음 물음에 알맞은 답을 고르세요.',
                question: 'What do you want to be?',
                answer: ["I want to be a singer.", "I want a candy."]
            },
            {
                type: 2,
                number: 12,
                category: 'Speaking',
                title: '12. 다음 물음에 알맞은 답을 고르세요.',
                question: 'Why do you like English?',
                answer: ["I like English a lot.", "Because it's easier than Math."]
            },
            {
                type: 2,
                number: 13,
                category: 'Speaking',
                title: '13. 다음 물음에 알맞은 답을 고르세요.',
                question: "What's the date today?",
                answer: ["It's Friday.", "It's the 1st."]
            },
            {
                type: 2,
                number: 14,
                category: 'Speaking',
                title: '14. 다음 물음에 알맞은 답을 고르세요.',
                question: 'A: {blank} ? <br/> B: He went to a water park.',
                answer: ["What did he do last summer?", 'What is he doing?']
            },
            {
                type: 2,
                number: 15,
                category: 'Speaking',
                title: '15. 다음 물음에 알맞은 답을 고르세요.',
                question: 'Can I help you?',
                answer: ["No, I can't. I'm busy.", "I'm making for someone."]
            },
            {
                type: 2,
                number: 16,
                category: 'Speaking',
                title: '16. 다음 물음에 알맞은 답을 고르세요.',
                question: 'Does it feel smooth?',
                answer: ['Yes, I feel happy.', 'No, it feels rough.']
            },
            {
                type: 2,
                number: 17,
                category: 'Speaking',
                title: '17. 다음 물음에 알맞은 답을 고르세요.',
                question: 'What are you going to do this weekend?',
                answer: ["I will stay home.", "I went to the library."]
            },
            {
                type: 2,
                number: 18,
                category: 'Speaking',
                title: '18. 다음 물음에 알맞은 답을 고르세요.',
                question: 'How many times have you been there?',
                answer: ["I've been there twice.", "I've been there before."]
            },
            {
                type: 2,
                number: 19,
                category: 'Speaking',
                title: '19. 다음 물음에 알맞은 답을 고르세요.',
                question: 'Which animal is faster, a cat or a polar bear?',
                answer: ["A cat is as fast as a polar bear.", "A polar bear is slow."]
            },
            {
                type: 2,
                number: 20,
                category: 'Speaking',
                title: '20. 다음 물음에 알맞은 답을 고르세요.',
                question: 'Where is the food court?',
                answer: ["There's the food court.", "It's across from the music store."]
            },
            {
                type: 4,
                number: 21,
                category: 'Reading',
                title: "21. What color is Sam's hair?",
                question: "Sam paints Ted.<br/>" +
                    "Ted has straight black hair and brown eyes.<br/>" +
                    "Ted paints Sam.<br/>" +
                    "Sam has short red hair and green eyes.",
                answer: ['black', 'red', "green"]
            },
            {
                type: 4,
                number: 22,
                category: 'Reading',
                title: '22. How long does it take for eaglets to grow strong wings?',
                question: "Six chicks live on a farm. They dig and make a garden. They walk down the hill to the blueberry bushes. The chicks pick the blueberries.",
                answer: ['They dig', 'They walk down', "They pick the blueberries"]
            },
            {
                type: 4,
                number: 23,
                category: 'Reading',
                title: '23. How does the mother bird stop the snake?',
                question: 'A mother and father bird make a nest.<br/>' +
                    'The mother bird lays four eggs.<br/>' +
                    'The snake wants to eat the eggs.<br/>' +
                    'The mother bird stops it with her beak.',
                answer: ['make a nest', 'eat the eggs', "use her beak"]
            },
            {
                type: 4,
                number: 24,
                category: 'Reading',
                title: '24. What bugs can jump?',
                question: 'Five bugs are playing in a field.<br/>' +
                    'Bee and Ladybug can fly.<br/>' +
                    'Grasshopper can jump.<br/>' +
                    'Ant can run fast.<br/>' +
                    "But poor Caterpillar can't fly or jump.<br/>" +
                    "He can't run fast.",
                answer: ['Lady bug', 'Grasshopper', "Caterpillar"]
            },
            {
                type: 4,
                number: 25,
                category: 'Reading',
                title: "25. Why are Lucy's writing tools happy?",
                question: 'Lucy draws a picture. The tools are happy.<br/>' +
                    'They can help her again.<br/>' +
                    'Her teacher praises the report. Lucy is proud',
                answer: ['Lucy draws a picture.', 'They can help her', "Her teacher praises the report."]
            },
            {
                type: 4,
                number: 26,
                category: 'Reading',
                title: '26. What does Jake teach Cal?',
                question: "There are many different dogs at the shelter. There are big dogs, little dogs, fat dogs, and skinny dogs.<br/>" +
                    "“Look, Jake, that dog with the black spots is staring at you,” says Dad.<br/>" +
                    "The dog's name is Cal. I tell Dad I want to take Cal for a walk. I know Cal is the perfect dog for me. We take him home.<br/>" +
                    "Cal is smart, and I teach him tricks. He can roll over and stand on this back legs.<br/>" +
                    "Cal walks with Dad and me to school everyday, and he sleeps with me every night. Our friendship is special.",
                answer: ['He teaches him to run fast.', 'He teaches him to put on a leash.', "He teaches him to do tricks."],
                isLong: true
            },
            {
                type: 4,
                number: 27,
                category: 'Reading',
                title: '27. How long does it take for eaglets to grow strong wings?',
                question: "The mother eagle lays up to three egss. When the eggs hatch, baby birds come out. These are the egalest.<br/>" +
                    "At first, eaglets cannot fly or see very well. Their parents have to feed them. They bring fish and meat to the nest.<br/>" +
                    "Before they can fly, eaglets have to grow dark feathers and strong wings. This takes about five months. Once they can fly, they learn to hunt. Over about five years, they grow into adult eagles.",
                answer: ['It takes about five years.', 'It takes about five hours.', "It takes about five months."],
                isLong: true
            },
            {
                type: 4,
                number: 28,
                category: 'Reading',
                title: '28. Why is the sky light in the morning?',
                question: "Although the sun seems to move, it does not. Day and night happen because the Earth moves.<br/>" +
                    "The Earth is round like a ball, and it spins on an axis. As it spins, only one side faces the sun. On this side, we see sunlight. It is day here. On the other side, there is no sunlight, It is night there.<br/>" +
                    "It takes twenty-four hours for the Earth to spin completely on its axis.<br/>" +
                    "As we sleep. our planet is working. It keeps spinning to bring the new day.",
                answer: ["It's because the sun sets.", "It's because the sun spins on its axis.", "It's because the sun rises."],
                isLong: true
            },
            {
                type: 4,
                number: 29,
                category: 'Reading',
                title: '29. What is the north of Alaska like?',
                question: "The north of Alaska is much colder than the south. Temperatures are very low. The ground and rivers freeze.<br/>" +
                    "It is warmer in the south, so most people live there. Crops grow well in the warmer weather, too.",
                answer: ['It is very cold. The ground and rivers freeze.', 'Crops grow here, and the temperatures are warm.', "It is very cold, but people can grow crops."],
                isLong: true
            },
            {
                type: 4,
                number: 30,
                category: 'Reading',
                title: "30. What is the first goal of the UN?",
                question: "The UN has four main goals that are about rights. The first is to have peace in the world.<br/>" +
                    "When there is a war, the UN can send soldiers to try to stop it.<br/>" +
                    "The second is to help countries cooperate. The third is to help people. It gives food and shelter when bad things happen. The fourth is to have a place for nations to meet and talk.",
                answer: ['It is to make nations meet and talk.', 'It is to help countries cooperate.', "It is to have peace in the world."],
                isLong: true
            },
            {
                type: 3,
                number: 31,
                category: 'Sentence',
                title: '31. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)',
                question: '너는 버스를 운전하니?',
                answer: ['you', 'drive', 'Do', '?', 'bus', 'a']
            },
            {
                type: 3,
                number: 32,
                category: 'Sentence',
                title: '32. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)',
                question: '나는 노래를 부르고 있지 않아.',
                answer: ['am', 'I', 'singing', 'not']
            },
            {
                type: 3,
                number: 33,
                category: 'Sentence',
                title: '33. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)',
                question: '그녀는 무엇을 하고 있나요?',
                answer: ['she', '?', 'is', 'What', 'doing']
            },
            {
                type: 3,
                number: 34,
                category: 'Sentence',
                title: '34. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)',
                question: '너는 너의 친구에게 방문할 것이니?.',
                answer: ['friend', 'visit', 'your', '?', "you", "Will"]
            },
            {
                type: 3,
                number: 35,
                category: 'Sentence',
                title: '35. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)',
                question: '우리는 쇼핑을 가지 않았다.',
                answer: ['not', 'We', 'shopping', 'go', 'did']
            },
            {
                type: 3,
                number: 36,
                category: 'Sentence',
                title: '36. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)',
                question: '그것은 달콤한 오렌지일 것이다.',
                answer: ['a', 'will', 'orange', 'sweet', 'It', 'be']
            },
            {
                type: 1,
                number: 37,
                category: 'Grammar',
                title: '37. 빈칸에 알맞은 말을 고르세요.',
                question: 'Your car {blank} very clean.',
                answer: ['a', 'are', 'is', 'has']
            },
            {
                type: 1,
                number: 38,
                category: 'Grammar',
                title: '38. 빈칸에 알맞은 말을 고르세요.',
                question: 'Jenny {blank} to elementary school last year.',
                answer: ['go', 'goes', 'goed', 'went']
            },
            {
                type: 1,
                number: 39,
                category: 'Grammar',
                title: '39. 빈칸에 알맞은 말을 고르세요.',
                question: 'A cow {blank} meat.',
                answer: ["don't eat", "doesn't eat", "doesn't eats", 'not eat']
            },
            {
                type: 1,
                number: 40,
                category: 'Grammar',
                title: '40. 빈칸에 알맞은 말을 고르세요.',
                question: 'William is not a big man, but he {blank} .',
                answer: ["look strong", "look strongly", "looks strong", 'looks strongly']
            },
        ]
    },
    {
        subject: 'intermediate',
        answer: [
            "얼다",
            "천둥",
            "shy",
            "add",
            "left",
            "생산하다",
            "고대의",
            "apologize",
            "habitat",
            "~할 것이다",
            "Will you visit your friend ?",
            "We did not go shopping",
            "It will be a sweet orange",
            "Math is more difficult than science",
            "Jenny is the tallest girl",
            "Which season do you like , spring or fall ?",
            "She was beautiful when she was young",
            "I was angry because you told a lie",
            "He learned golf before he was 10",
            "If you practice hard you will beat him",
            "He teaches him to do tricks.",
            "It takes about five months.",
            "It's because the sun spins on its axis.",
            "It is very cold. The ground and rivers freeze.",
            "It is to have peace in the world.",
            "They are used to catch bad dreams.",
            "They learn to talk about voting at home.",
            "He won a meal at a music competition.",
            "It sends scientists to other planets.",
            "He worked at a store and cleaned planes.",
            "watch",
            "went",
            "doesn't eat",
            "looks strong",
            "will be able to",
            "are given",
            "for",
            "of",
            "to call",
            "disappointed",
        ],
        data: [
            {
                type: 1,
                number: 1,
                category: 'Vocabulary',
                title: '1. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: 'freeze',
                answer: ['얼다', '두꺼운', '얇은']
            },
            {
                type: 1,
                number: 2,
                category: 'Vocabulary',
                title: '2. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: 'thunder',
                answer: ['번개', '토네이도', '천둥']
            },
            {
                type: 1,
                number: 3,
                category: 'Vocabulary',
                title: '3. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: '부끄러운',
                answer: ['patient', 'shy', 'nervous']
            },
            {
                type: 1,
                number: 4,
                category: 'Vocabulary',
                title: '4. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: '더하다',
                answer: ['invite', 'minus', 'add']
            },
            {
                type: 1,
                number: 5,
                category: 'Vocabulary',
                title: '5. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: 'right 의 반대말',
                answer: ['back', 'left', 'straight']
            },
            {
                type: 1,
                number: 6,
                category: 'Vocabulary',
                title: '6. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: 'produce',
                answer: ['중요한', '생산하다', '모으다']
            },
            {
                type: 1,
                number: 7,
                category: 'Vocabulary',
                title: '7. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: 'ancient',
                answer: ['광범위한', '현대의', '고대의']
            },
            {
                type: 1,
                number: 8,
                category: 'Vocabulary',
                title: '8. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: '사과하다',
                answer: ['apologize', 'gigantic', 'uncomfortable']
            },
            {
                type: 1,
                number: 9,
                category: 'Vocabulary',
                title: '9. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: '서식지',
                answer: ['environment', 'habitat', 'adapt']
            },
            {
                type: 1,
                number: 10,
                category: 'Vocabulary',
                title: '10. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: 'be going to',
                answer: ['~할 것이다', '갈 것이다', '~하는 중이다']
            },
            {
                type: 3,
                number: 11,
                category: 'Sentence',
                title: '11. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)',
                question: '너는 너의 친구에게 방문할 것이니?',
                answer: ['friend', 'visit', 'your', '?', "you", "Will"]
            },
            {
                type: 3,
                number: 12,
                category: 'Sentence',
                title: '12. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)',
                question: '우리는 쇼핑을 가지 않았다.',
                answer: ['not', 'We', 'shopping', 'go', 'did']
            },
            {
                type: 3,
                number: 13,
                category: 'Sentence',
                title: '13. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)',
                question: '그것은 달콤한 오렌지일 것이다.',
                answer: ['a', 'will', 'orange', 'sweet', 'It', 'be']
            },
            {
                type: 3,
                number: 14,
                category: 'Sentence',
                title: '14. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)',
                question: '수학이 과학보다 더 어렵다.',
                answer: ['difficult', 'more', 'is', 'science', 'than', 'Math']
            },
            {
                type: 3,
                number: 15,
                category: 'Sentence',
                title: '15. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)',
                question: 'Jenny는 가장 키가 큰 소녀이다.',
                answer: ['the', 'Jenny', 'girl', 'tallest', 'is']
            },
            {
                type: 3,
                number: 16,
                category: 'Sentence',
                title: '16. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)',
                question: '너는 봄과 가을 중에 어느 계절을 좋아하니?',
                answer: ['like', 'Which', ',', 'spring', 'or', 'do', 'fall', 'you', 'season', '?']
            },
            {
                type: 3,
                number: 17,
                category: 'Sentence',
                title: '17. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)',
                question: '그녀는 젊었을 때 아름다웠다.',
                answer: ['was', 'She', 'beautiful', 'she', 'when', 'young', 'was']
            },
            {
                type: 3,
                number: 18,
                category: 'Sentence',
                title: '18. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)',
                question: '네가 거짓말을 했기 때문에 나는 화가 났다.',
                answer: ['told', 'you', 'because', 'was', 'I', 'angry', 'lie', 'a']
            },
            {
                type: 3,
                number: 19,
                category: 'Sentence',
                title: '19. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)',
                question: '그는 10살이 되기 전에 골프를 배웠다.',
                answer: ['was', 'before', 'golf', 'learned', 'he', 'He', '10']
            },
            {
                type: 3,
                number: 20,
                category: 'Sentence',
                title: '20. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)',
                question: '열심히 연습한다면 너는 그를 이길 것이다.',
                answer: ['hard', 'you', 'you', 'practice', 'will', 'him', 'beat', 'If']
            },
            {
                type: 4,
                number: 21,
                category: 'Reading',
                title: '21. What does Jake teach Cal?',
                question: "There are many different dogs at the shelter. There are big dogs, little dogs, fat dogs, and skinny dogs.<br/>" +
                    "“Look, Jake, that dog with the black spots is staring at you,” says Dad.<br/>" +
                    "The dog's name is Cal. I tell Dad I want to take Cal for a walk. I know Cal is the perfect dog for me. We take him home.<br/>" +
                    "Cal is smart, and I teach him tricks. He can roll over and stand on this back legs.<br/>" +
                    "Cal walks with Dad and me to school everyday, and he sleeps with me every night. Our friendship is special.",
                answer: ['He teaches him to run fast.', 'He teaches him to put on a leash.', "He teaches him to do tricks."],
                isLong: true
            },
            {
                type: 4,
                number: 22,
                category: 'Reading',
                title: '22. How long does it take for eaglets to grow strong wings?',
                question: "The mother eagle lays up to three egss. When the eggs hatch, baby birds come out. These are the egalest.<br/>" +
                    "At first, eaglets cannot fly or see very well. Their parents have to feed them. They bring fish and meat to the nest.<br/>" +
                    "Before they can fly, eaglets have to grow dark feathers and strong wings. This takes about five months. Once they can fly, they learn to hunt. Over about five years, they grow into adult eagles.",
                answer: ['It takes about five years.', 'It takes about five hours.', "It takes about five months."],
                isLong: true
            },
            {
                type: 4,
                number: 23,
                category: 'Reading',
                title: '23. Why is the sky light in the morning?',
                question: "Although the sun seems to move, it does not. Day and night happen because the Earth moves.<br/>" +
                    "The Earth is round like a ball, and it spins on an axis. As it spins, only one side faces the sun. On this side, we see sunlight. It is day here. On the other side, there is no sunlight, It is night there.<br/>" +
                    "It takes twenty-four hours for the Earth to spin completely on its axis.<br/>" +
                    "As we sleep. our planet is working. It keeps spinning to bring the new day.",
                answer: ["It's because the sun sets.", "It's because the sun spins on its axis.", "It's because the sun rises."],
                isLong: true
            },
            {
                type: 4,
                number: 24,
                category: 'Reading',
                title: '24. What is the north of Alaska like?',
                question: "The north of Alaska is much colder than the south. Temperatures are very low. The ground and rivers freeze.<br/>" +
                    "It is warmer in the south, so most people live there. Crops grow well in the warmer weather, too.",
                answer: ['It is very cold. The ground and rivers freeze.', 'Crops grow here, and the temperatures are warm.', "It is very cold, but people can grow crops."],
                isLong: true
            },
            {
                type: 4,
                number: 25,
                category: 'Reading',
                title: "25. What is the first goal of the UN?",
                question: "The UN has four main goals that are about rights. The first is to have peace in the world.<br/>" +
                    "When there is a war, the UN can send soldiers to try to stop it.<br/>" +
                    "The second is to help countries cooperate. The third is to help people. It gives food and shelter when bad things happen. The fourth is to have a place for nations to meet and talk.",
                answer: ['It is to make nations meet and talk.', 'It is to help countries cooperate.', "It is to have peace in the world."],
                isLong: true
            },
            {
                type: 4,
                number: 26,
                category: 'Reading',
                title: '26. What are dream catchers used for?',
                question: `“This is a dream catcher,” said Nokomis. “Our people have always made these. The circles are symbols of strength. We can hang it above your bed to catch your bad dreams in the web. It will also give you the courage to do your presentation.”`,
                answer: ['They are used to catch spiders.', 'They are used to decorate rooms.', "They are used to catch bad dreams."],
                isLong: true
            },
            {
                type: 4,
                number: 27,
                category: 'Reading',
                title: '27. What do kids in Kids Voting USA learn?',
                question: "[Teaching Kids to Vote]<br/>" +
                    "Many Americans don't vote. They think it is too hard or it takes a long time. A group called Kids Voting USA wants to change that and convince more people to vote.<br/>" +
                    " This group teaches kids about voting. They learn how to elect good leaders. They then talk about votiing at home. Kids also read about the candidates. This way, they can vote for those who share their ideas.",
                answer: ['They learn to choose good laws.', 'They learn how to make a ballot.', "They learn to talk about voting at home."],
                isLong: true
            },
            {
                type: 4,
                number: 28,
                category: 'Reading',
                title: '28. What is Not true about Wolfgang Amadeus Mozart?',
                question: "The composer Wolfgang Amadeus Mozart was born in 1756. At the age of three, he knew about chords, tone, and tempo. As he grew, he learned many styles of music and came up with his own style. People were amazed because his music was so rich and complex. Some believe Mozart wrote the greatest music ever.",
                answer: ["His talent was in composing music", "He was loved for his rich and complex music.", "He won a meal at a music competition."],
                isLong: true
            },
            {
                type: 4,
                number: 29,
                category: 'Reading',
                title: '29. What does the Hubble Space Telescope do?',
                question: "Scientists built the huge Hubble Space Telescope to know even more. In 1990, it was sent into space to orbit the Earth, It can get up close to take clear photos of stars and planets. Scientists study these photos to find out what's going on in space.<br/>" +
                    "There are always more questions about space. However, scientist are always finding new ways to get the answers.",
                answer: ["It collects rocks from the moon's surface.", 'It sends scientists to other planets.', "It takes clear photos of planets and stars."],
                isLong: true
            },
            {
                type: 4,
                number: 30,
                category: 'Reading',
                title: "30. How did Armstrong pay for flying lessons?",
                question: "Armstrong was smart and a hard worker. He did all he could to reach his goal. He read flight magazines to research flying. He worked at a store and did odd jobs, such as cleaning planes. This gave him money for fling lessons He got his pilot's license at sixteen years old. He could fly a plane before he could drive a car!",
                answer: ['He joined NASA and went on space missions,', 'He did research and wrote for flight magazines.', "He worked at a store and cleaned planes."],
                isLong: true
            },
            {
                type: 1,
                number: 31,
                category: 'Grammar',
                title: '31. 빈칸에 알맞은 말을 고르세요.',
                question: "My parents {blank} the daily 9 o'clock news these days?",
                answer: ['watch', 'watchs', 'watches', 'watched']
            },
            {
                type: 1,
                number: 32,
                category: 'Grammar',
                title: '32. 빈칸에 알맞은 말을 고르세요.',
                question: 'Jihyun {blank} to elementary school in the US last year, but now she is not.',
                answer: ['go', 'goes', 'goed', 'went']
            },
            {
                type: 1,
                number: 33,
                category: 'Grammar',
                title: '33. 빈칸에 알맞은 말을 고르세요.',
                question: 'A cow {blank} meat.',
                answer: ["don't eat", "doesn't eat", "doesn't eats", 'not eat']
            },
            {
                type: 1,
                number: 34,
                category: 'Grammar',
                title: '34. 빈칸에 알맞은 말을 고르세요.',
                question: 'James is not a big man, but he {blank} .',
                answer: ['look strong', 'look strongly', 'looks strong', 'looks strongly']
            },
            {
                type: 1,
                number: 35,
                category: 'Grammar',
                title: '35. 빈칸에 알맞은 말을 고르세요.',
                question: 'Maybe one day she {blank} be a great singer like BoA.',
                answer: ['will can', 'will be able to', 'will be going to', 'can will']
            },
            {
                type: 1,
                number: 36,
                category: 'Grammar',
                title: '36. 빈칸에 알맞은 말을 고르세요.',
                question: 'Usually, students {blank} most of their textbooks in February.',
                answer: ['give', 'are given', 'gave', 'are gave']
            },
            {
                type: 1,
                number: 37,
                category: 'Grammar',
                title: '37. 빈칸에 알맞은 말을 고르세요.',
                question: "(들어갈 수 없는 번호를 고르세요)<br/>" +
                    "She saved money {blank} buy luxurious cosmetics.",
                answer: ['to', 'for', 'in order to', 'so as to']
            },
            {
                type: 1,
                number: 38,
                category: 'Grammar',
                title: '38. 빈칸에 알맞은 말을 고르세요.',
                question: 'It was foolish {blank} him to miss such a nice opportunity.',
                answer: ['of', 'for', 'to', 'with']
            },
            {
                type: 1,
                number: 39,
                category: 'Grammar',
                title: '39. 빈칸에 알맞은 말을 고르세요.',
                question: "Please don't forget {blank} me when you get home.",
                answer: ["to call", "to calling", "calling", 'about calling']
            },
            {
                type: 1,
                number: 40,
                category: 'Grammar',
                title: '40. 빈칸에 알맞은 말을 고르세요.',
                question: 'Mom was very {blank} with my poor score on my math exam.',
                answer: ["disappoint", "disappoints", "disappointing", 'disappointed']
            },
        ]
    },
    {
        subject: 'advanced',
        answer: [
            "생산하다",
            "고대의",
            "apologize",
            "habitat",
            "~할 것이다",
            "취소하다",
            "존재하다",
            "galaxy",
            "불법의",
            "be used to Vr",
            "Which season do you like , spring or fall ?",
            "She was beautiful when she was young",
            "I was angry because you told a lie",
            "He learned golf before he was 10",
            "If you practice hard you will beat him",
            "Young children can't look after themselves",
            "It is important to know yourself",
            "His invention made people comfortable",
            "She had her husband do the laundry",
            "There are some children who are playing baseball",
            "They are used to catch bad dreams.",
            "They learn to talk about voting at home.",
            "He won a meal at a music competition.",
            "It sends scientists to other planets.",
            "He worked at a store and cleaned planes.",
            "She dropped pebble after pebble into the pitcher.",
            "It loses its color when the algae stop making food.",
            "they did not have the same rights that men had.",
            "It is because he liked to learn from his failures.",
            "They were round and stamped with designs that stated their value.",
            "are given",
            "of",
            "to call",
            "disappointed",
            "will join",
            "were",
            "What",
            "where",
            "pro gamers are",
            "Neither do I",
        ],
        data: [
            {
                type: 1,
                number: 1,
                category: 'Vocabulary',
                title: '1. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: 'produce',
                answer: ['중요한', '생산하다', '모으다']
            },
            {
                type: 1,
                number: 2,
                category: 'Vocabulary',
                title: '2. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: 'ancient',
                answer: ['광범위한', '현대의', '고대의']
            },
            {
                type: 1,
                number: 3,
                category: 'Vocabulary',
                title: '3. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: '사과하다',
                answer: ['apologize', 'gigantic', 'uncomfortable']
            },
            {
                type: 1,
                number: 4,
                category: 'Vocabulary',
                title: '4. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: '서식지',
                answer: ['environment', 'habitat', 'adapt']
            },
            {
                type: 1,
                number: 5,
                category: 'Vocabulary',
                title: '5. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: "be going to",
                answer: ['~할 것이다', '갈 것이다', '~하는 중이다']
            },
            {
                type: 1,
                number: 6,
                category: 'Vocabulary',
                title: '6. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: 'cancel',
                answer: ['이야기하다', '취소하다', '성공하다']
            },
            {
                type: 1,
                number: 7,
                category: 'Vocabulary',
                title: '7. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: 'exist',
                answer: ['비상구', '멸종하다', '존재하다']
            },
            {
                type: 1,
                number: 8,
                category: 'Vocabulary',
                title: '8. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: '은하수',
                answer: ['moon', 'galaxy', 'stars']
            },
            {
                type: 1,
                number: 9,
                category: 'Vocabulary',
                title: '9. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: 'illegal',
                answer: ['불법의', '교도소', '법원']
            },
            {
                type: 1,
                number: 10,
                category: 'Vocabulary',
                title: '10. 다음 단어의 우리말 또는 영어를 찾으시오.',
                question: "'~하는데 사용되다'<br/>" +
                          "(Vr = 동사원형)",
                answer: ['be used to Vr', 'be used to ~ing', 'used to Vr']
            },
            {
                type: 3,
                number: 11,
                category: 'Sentence',
                title: '11. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)',
                question: '너는 봄과 가을 중에 어느 계절을 좋아하니?',
                answer: ['like', 'Which', ',', 'spring', 'or', 'do', 'fall', 'you', 'season', '?']
            },
            {
                type: 3,
                number: 12,
                category: 'Sentence',
                title: '12. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)',
                question: '그녀는 젊었을 때 아름다웠다.',
                answer: ['was', 'She', 'beautiful', 'she', 'when', 'young', 'was']
            },
            {
                type: 3,
                number: 13,
                category: 'Sentence',
                title: '13. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)',
                question: '네가 거짓말을 했기 때문에 나는 화가 났다.',
                answer: ['told', 'you', 'because', 'was', 'I', 'angry', 'lie', 'a']
            },
            {
                type: 3,
                number: 14,
                category: 'Sentence',
                title: '14. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)',
                question: '그는 10살이 되기 전에 골프를 배웠다.',
                answer: ['was', 'before', 'golf', 'learned', 'he', 'He', '10']
            },
            {
                type: 3,
                number: 15,
                category: 'Sentence',
                title: '15. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)',
                question: '열심히 연습한다면 너는 그를 이길 것이다.',
                answer: ['hard', 'you', 'you', 'practice', 'will', 'him', 'beat', 'If']
            },
            {
                type: 3,
                number: 16,
                category: 'Sentence',
                title: '16. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)',
                question: '어린 아이들은 그들 자신을 돌볼 수 없다.',
                answer: ["can't", 'themselves', 'children', 'look', 'Young', 'after']
            },
            {
                type: 3,
                number: 17,
                category: 'Sentence',
                title: '17. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)',
                question: '너 자신을 아는 것이 중요하다',
                answer: ['important', 'It', 'to', 'is', 'yourself', 'know']
            },
            {
                type: 3,
                number: 18,
                category: 'Sentence',
                title: '18. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)',
                question: '그의 발명품은 사람들을 편리하게 만들어주었다.',
                answer: ['made', 'comfortable', 'invention', 'people', 'His']
            },
            {
                type: 3,
                number: 19,
                category: 'Sentence',
                title: '19. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)',
                question: '그녀는 남편에게 빨래를 하게 했다.',
                answer: ['had', 'her', 'the', 'She', 'husband', 'do', 'laundry']
            },
            {
                type: 3,
                number: 20,
                category: 'Sentence',
                title: '20. 문장에 맞게 순서대로 넣으세요. (마우스로 드래그하여 완성하세요.)',
                question: '야구를 하는 몇 명의 아이들이 있다.',
                answer: ['some', 'There', 'playing', 'are', 'children', 'who', 'baseball', 'are']
            },
            {
                type: 4,
                number: 21,
                category: 'Reading',
                title: '21. What are dream catchers used for?',
                question: `“This is a dream catcher,” said Nokomis. “Our people have always made these. The circles are symbols of strength. We can hang it above your bed to catch your bad dreams in the web. It will also give you the courage to do your presentation.”`,
                answer: ['They are used to catch spiders.', 'They are used to decorate rooms.', "They are used to catch bad dreams."],
                isLong: true
            },
            {
                type: 4,
                number: 22,
                category: 'Reading',
                title: '22. What do kids in Kids Voting USA learn?',
                question: "[Teaching Kids to Vote]<br/>" +
                    "Many Americans don't vote. They think it is too hard or it takes a long time. A group called Kids Voting USA wants to change that and convince more people to vote.<br/>" +
                    " This group teaches kids about voting. They learn how to elect good leaders. They then talk about votiing at home. Kids also read about the candidates. This way, they can vote for those who share their ideas.",
                answer: ['They learn to choose good laws.', 'They learn how to make a ballot.', "They learn to talk about voting at home."],
                isLong: true
            },
            {
                type: 4,
                number: 23,
                category: 'Reading',
                title: '23. What is Not true about Wolfgang Amadeus Mozart?',
                question: "The composer Wolfgang Amadeus Mozart was born in 1756. At the age of three, he knew about chords, tone, and tempo. As he grew, he learned many styles of music and came up with his own style. People were amazed because his music was so rich and complex. Some believe Mozart wrote the greatest music ever.",
                answer: ["His talent was in composing music", "He was loved for his rich and complex music.", "He won a meal at a music competition."],
                isLong: true
            },
            {
                type: 4,
                number: 24,
                category: 'Reading',
                title: '24. What does the Hubble Space Telescope do?',
                question: "Scientists built the huge Hubble Space Telescope to know even more. In 1990, it was sent into space to orbit the Earth, It can get up close to take clear photos of stars and planets. Scientists study these photos to find out what's going on in space.<br/>" +
                    "There are always more questions about space. However, scientist are always finding new ways to get the answers.",
                answer: ["It collects rocks from the moon's surface.", 'It sends scientists to other planets.', "It takes clear photos of planets and stars."],
                isLong: true
            },
            {
                type: 4,
                number: 25,
                category: 'Reading',
                title: "25. How did Armstrong pay for flying lessons?",
                question: "Armstrong was smart and a hard worker. He did all he could to reach his goal. He read flight magazines to research flying. He worked at a store and did odd jobs, such as cleaning planes. This gave him money for fling lessons He got his pilot's license at sixteen years old. He could fly a plane before he could drive a car!",
                answer: ['He joined NASA and went on space missions,', 'He did research and wrote for flight magazines.', "He worked at a store and cleaned planes."],
                isLong: true
            },
            {
                type: 4,
                number: 26,
                category: 'Reading',
                title: '26. How did the crow make the water level rise?',
                question: "The crow jumped down and used her beak to pick up the pebble. She flew back to the pitcher and dropped the pebble in to the water. The pebble fell into the pitcher, hitting the water with a plop. Then she saw another pebble nearby. She picked up this pebble and dropped it into the pitcher, too. She did this over and over again. Slowly, the water lever began to rise.",
                answer: ['She waited for drops of rain to drip through the roof?', 'She waited for the farmer to put more water into the pitcher', "She dropped pebble after pebble into the pitcher."],
                isLong: true
            },
            {
                type: 4,
                number: 27,
                category: 'Reading',
                title: '27. What causes the coral to lose its color?',
                question: "A woman keeps moving until she sees some white coal in the reef. She snaps a photo. The coral was once very colorful. Now it is bleached white. The coral is sick! Corals are only healthy when the ocean is healthy. Climate change and pollution break the connection between the coral and the algae. The algae stop making food, which causes the coral to get rid of them. Without algae, the coral staves and loses it's color.",
                answer: ['It loses its color when the algae stop making food.', 'It loses its color when the algae go through photosynthesis.', "It loses its color when the algae make home inside the coral."],
                isLong: true
            },
            {
                type: 4,
                number: 28,
                category: 'Reading',
                title: "28. It was hard for Elizabeth's father to help women because {blank}",
                question: "Elizabeth's father was a lawyer and judge. She listened carefully when women came to see him for legal advice. But she was often disappointed. Her father was unable to help most of the,. Women did not have the same rights that men had. Married women could not own property or vote. The more Elizabeth heard about such unfair laws, the more determined she was to do something.<br/>" +
                    "Elizabeth began to read her father's law books. She marked the laws that were unfair to women. Her father told her that she should try to change those laws when she grew up. That way, she could make life better for women.",
                answer: ["they did not know much about the law.", "they did not have the same rights that men had.", "they listed all the names of women who supported her ideas."],
                isLong: true
            },
            {
                type: 4,
                number: 29,
                category: 'Reading',
                title: '29. Why was Babe Ruth known as the king of strikeouts?',
                question: "Successful people are not afraid of setbacks. Babe Ruth was one of the most famous baseball players in the world. At first, he wasn't so successful, He struck out 1,330 time and was known as the king of strikeouts. But he didn't let this stop him, and he went on to score 714 home runs. He showed that successful people learn from failure and adjust the way they do things.",
                answer: ["It is because he scored 714 home runs.", 'It is because he liked to learn from his failures.', "It is because he struck out 1,330 times."],
                isLong: true
            },
            {
                type: 4,
                number: 30,
                category: 'Reading',
                title: "30. What did the first gold and silver coins look like?",
                question: "People started using metal coins in China around 1000 B.C.E. Soon, currency became a global idea. In 700 B.C.E., gold and silver coins were popular in Europe and the Middle East. These coins were usually round. After being weighed on a scale to determine their value, they were stamped with different designs that stated their value.",
                answer: ['They were round and stamped with designs that stated their value.', 'They were different colors, depending on their value.', "They showed the amount of gold or silver a person had in the bank."],
                isLong: true
            },
            {
                type: 1,
                number: 31,
                category: 'Grammar',
                title: '31. 빈칸에 알맞은 말을 고르세요.',
                question: 'Usually, students {blank} most of their textbooks in February.',
                answer: ['give', 'are given', 'gave', 'are gave']
            },
            {
                type: 1,
                number: 32,
                category: 'Grammar',
                title: '32. 빈칸에 알맞은 말을 고르세요.',
                question: 'It was foolish {blank} him to miss such a nice opportunity.',
                answer: ['of', 'for', 'to', 'with']
            },
            {
                type: 1,
                number: 33,
                category: 'Grammar',
                title: '33. 빈칸에 알맞은 말을 고르세요.',
                question: "Please don't forget {blank} me when you get home.",
                answer: ["to call", "to calling", "calling", 'about calling']
            },
            {
                type: 1,
                number: 34,
                category: 'Grammar',
                title: '34. 빈칸에 알맞은 말을 고르세요.',
                question: 'Mom was very {blank} with my poor score on my math exam.',
                answer: ["disappoint", "disappoints", "disappointing", 'disappointed']
            },
            {
                type: 1,
                number: 35,
                category: 'Grammar',
                title: '35. 빈칸에 알맞은 말을 고르세요.',
                question: "I don't know if Jack {blank} us or not.",
                answer: ['join', 'joins', 'will join', 'will joins']
            },
            {
                type: 1,
                number: 36,
                category: 'Grammar',
                title: '36. 빈칸에 알맞은 말을 고르세요.',
                question: "If I {blank} you, I would divorce him.",
                answer: ['am', 'are', 'was', 'were']
            },
            {
                type: 1,
                number: 37,
                category: 'Grammar',
                title: '37. 빈칸에 알맞은 말을 고르세요.',
                question: '{blank} he really wants to do is not just to watch, but to play himself.',
                answer: ['What', 'That', 'Which', 'It']
            },
            {
                type: 1,
                number: 38,
                category: 'Grammar',
                title: '38. 빈칸에 알맞은 말을 고르세요.',
                question: 'I know a nice restaurant {blank} the food is god and very cheap.',
                answer: ["that", "where", "what", 'which']
            },
            {
                type: 1,
                number: 39,
                category: 'Grammar',
                title: '39. 빈칸에 알맞은 말을 고르세요.',
                question: 'A number of {blank} going to participate in the tournament.',
                answer: ["pro gamer is", "pro gamers are", "pro gamer are", 'pro gamers is']
            },
            {
                type: 1,
                number: 40,
                category: 'Grammar',
                title: '40. 빈칸에 알맞은 말을 고르세요.',
                question: "A : I don't like his songs.<br/>" +
                    "B : {blank} . He sings badly.",
                answer: ["Neither do I", "Neither am I", "So do I", 'So am I']
            },
        ]
    }
]

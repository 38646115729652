import styled from "styled-components";

const Button = ({children, ...props}) => {
    return (
        <ThemeButtonWrap {...props}>
            {children}
        </ThemeButtonWrap>
    )
};

const ButtonWrap = styled.button`
  opacity: ${({disabled}) => disabled ? '0.3' : '1'};
  transition: opacity 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  
  height: ${({size}) => {
    switch (size) {
      case 'xs':
        return '35px';
      case 'sm':
        return '45px';
      case 'md':
        return '60px';
      case 'full':
        return '100%';
    }
  }};
  border-radius: ${({shape}) => {
    switch (shape) {
      default:
      case 'rectangle':
        return '15px;';
      case 'oval':
        return '23px;';
      case 'circle':
        return '100%;';
    }
  }};
`;

const ThemeButtonWrap = styled(ButtonWrap)`
  ${({color}) => {
    switch (color) {
      case 'main':
        return `
            background-color: #CC7EB2;
            color: #fff;
            padding: 25px;
            box-shadow: rgb(0 0 0 / 15%) 0 0 6px 0;
        `;
      case 'white':
        return `
            background-color: #fff;
            color: #999;
            border: 1px solid #eee;
            padding: 25px;
            box-shadow: rgb(0 0 0 / 15%) 0 0 6px 0;
        `;
      case 'gray':
        return `
            background-color: #F2F2F2;
            color: #4F4F4F;
            border-radius: 8px;
            padding: 10px;
        `;
      case 'muted':
        return `
            color: #4F4F4F;
        `;
      default:
        return;
    }
  }};
`

export default Button;

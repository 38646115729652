import Button from "../../atoms/button";
import styled from "styled-components";
import {useCallback, useEffect} from "react";
import history from "../../../utils/history";
import {useLocation} from "react-router-dom";
import {insertReportRequestAction} from "../../../redux/report/reducer";
import {shallowEqual, useDispatch, useSelector} from "react-redux";

export const QuizFooterItem = ({answer, type, index, total, setIndex, choose, setChoose}) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const info = useSelector(((state) => state.user.info), shallowEqual);

    const onPrev = useCallback(() => {
        if (index === 0) {
            history.push('/');
        } else {
            setIndex(index - 1);
        }
    }, [index]);

    const onNext = useCallback(() => {
        if (type === 3) {
            const blankEl = document.querySelectorAll('[name=dummy]');
            const blankData = [];
            const answerEl = document.querySelectorAll('[name=answer]');
            const answerData = [];

            blankEl.forEach((el) => {
                blankData.push({
                    value: el.getAttribute('value'),
                    x: el.getBoundingClientRect().x,
                    y: el.getBoundingClientRect().y,
                });
            });

            answerEl.forEach((el) => {
                answerData.push({
                    value: el.innerHTML,
                    x: el.getBoundingClientRect().x,
                    y: el.getBoundingClientRect().y,
                });
            });

            const result = blankData.map((blank) => {
                let stop = false;
                answerData.forEach((answer) => {
                    if (blank.x - 10 < answer.x && answer.x < blank.x + 10) {
                        if (blank.y - 10 < answer.y && answer.y < blank.y + 10) {
                            stop = true;
                        }
                    }
                });

                return stop;
            });

            answerData.sort((a, b) => a.x < b.x ? -1 : a.x > b.x ? 1 : 0);

            const answerFirstData = answerData.filter(i => i.y < 490);
            const answerSecondData = answerData.filter(i => i.y > 490);

            if (result.filter((v) => !v).length === 0) {
                if ((index + 1) === total) {
                    const score = checkScore();
                    onNextPageByScore(score);
                } else {
                    setIndex(index + 1);
                    setChoose((prev) => ({
                        ...prev,
                        [index + 1]: answerFirstData.map(i => i.value).join(' ') + ' ' + answerSecondData.map(i => i.value).join(' ')
                    }));

                    answerEl.forEach((el) => {
                        el.style.top = '';
                        el.style.left = '';
                    });
                }
            }
        } else {
            if ((index + 1) === total) {
                const score = checkScore();
                onNextPageByScore(score);
            } else {
                setIndex(index + 1);
            }
        }
    }, [index, choose]);

    const checkScore = useCallback(() => {
        let score = 0;
        for (let i of Object.keys(choose)) {
            if (answer[i - 1] === choose[i]) {
                if (location.pathname.indexOf('preTestA') !== -1 || location.pathname.indexOf('preTestB') !== -1) {
                    score += 10;
                }
            }
        }

        return score;
    }, [answer, choose]);

    const onNextPageByScore = useCallback((score) => {
        if (location.pathname.indexOf('preTestA') !== -1) {
            insertPreTestResult('preTestA', score);

            if (score < 61) {
                history.push('/quiz/beginner');
            } else {
                history.push('/quiz/starter');
            }
        } else if (location.pathname.indexOf('preTestB') !== -1) {
            insertPreTestResult('preTestB', score);

            if (score < 61) {
                history.push('/quiz/basic');
            } else if (score < 91) {
                history.push('/quiz/intermediate');
            } else {
                history.push('/quiz/advanced');
            }
        } else if (location.pathname.indexOf('beginner') !== -1 ||
            location.pathname.indexOf('starter') !== -1 ||
            location.pathname.indexOf('basic') !== -1 ||
            location.pathname.indexOf('intermediate') !== -1 ||
            location.pathname.indexOf('advanced') !== -1) {
            history.push('/result', {choose, subject: location.pathname.split('/')[2]});
        }
    }, [choose]);

    const insertPreTestResult = useCallback((level, score) => {
        localStorage.setItem('index', 0);
        localStorage.setItem('choose', '{}');

        dispatch(insertReportRequestAction({
            name: info.name,
            school: info.school,
            grade: info.grade,
            birth: `${info.birth1}.${info.birth2}.${info.birth3}`,
            level,
            score,
            choose: JSON.stringify(choose),
        }));
    }, [info, choose])

    return (
        <QuizFooterWrap>
            {
                index !== 0 && <Button color={'white'} shape={'rectangle'} onClick={onPrev}> PREV </Button>
            }

            <Button color={'main'} shape={'rectangle'} onClick={onNext} disabled={type !== 3 ? !choose[Number(index) + 1] : false}>
                {(index + 1) === total ? 'SUBMIT' : 'NEXT'}
            </Button>
        </QuizFooterWrap>
    )
}

const QuizFooterWrap = styled.div`
  display: flex;
  padding: 30px 0;
  border-top: 1px solid #eee;
  align-items: center;
  justify-content: center;
  font-size: 21px;
  font-weight: bold;
  gap: 30px;

  & > button {
    width: 500px;
  }
`

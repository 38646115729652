import {
    SHOW_ALERT_REQUEST
} from "./constants";
import produce from "immer";

const initialState = {
    showAlert: false,
    title: '',
    component: null,
    onSubmit: () => {},
}

export const showAlertRequestAction = (data) => {
    return {
        type: SHOW_ALERT_REQUEST,
        data,
    }
}

const reducer = (state = initialState, action) => produce(state, (draft) => {
    switch (action.type) {
        case SHOW_ALERT_REQUEST:
            draft.showAlert = action.data.showAlert;
            draft.onSubmit = action.data.onSubmit;
            draft.component = action.data.component;
            draft.title = action.data.title;
            break;
    }
});

export default reducer;


import styled from "styled-components";
import {useCallback} from "react";

const Input = ({...props}) => {

    const onChange = useCallback((e) => {
        if (props.type === 'number') {
            e.target.value = Number(e.target.value.replace(/[^0-9]/g,''));
            props.onChange(e);
        } else {
            props.onChange(e);
        }
    }, []);

    return (
        <ThemeInputWrap {...props} onChange={onChange} />
    );
}

const InputWrap = styled.input`
  border: 0;
  font-size: 14px;
  margin: ${({margin}) => margin};
`;

const ThemeInputWrap = styled(InputWrap)`
  ${({theme}) => {
    switch (theme) {
      case 'normal':
        return `
            width: 100%;
            height: 45px;
            padding: 10px;
            border: 1px solid #eee;
            border-radius: 6px;
            transition: all 0.3s;
            
            &:focus {
              border-color: #CC7EB2;
            }
        `;
      default:
        return;
    }
  }};
`

export default Input;

import {
    DELETE_USER_FAILURE,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    INSERT_USER_FAILURE,
    INSERT_USER_REQUEST,
    INSERT_USER_SUCCESS,
    LOAD_USER_FAILURE,
    LOAD_USER_REQUEST,
    LOAD_USER_SUCCESS,
    LOAD_USERS_FAILURE,
    LOAD_USERS_REQUEST,
    LOAD_USERS_SUCCESS,
    LOG_IN_CHECK_FAILURE,
    LOG_IN_CHECK_REQUEST,
    LOG_IN_CHECK_SUCCESS,
    LOG_IN_FAILURE,
    LOG_IN_REQUEST,
    LOG_IN_SUCCESS,
    LOG_OUT_FAILURE,
    LOG_OUT_REQUEST,
    LOG_OUT_SUCCESS, SET_USER_INFO_FAILURE,
    SET_USER_INFO_REQUEST, SET_USER_INFO_SUCCESS,
    UPDATE_USER_FAILURE,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS, WITHDRAW_FAILURE, WITHDRAW_REQUEST, WITHDRAW_SUCCESS
} from "./constants";
import produce from "immer";

const initialState = {
    me: {},
    info: {},
    user: {},
    users: [],
    logInLoading: false,
    logInDone: false,
    logInError: false,
    logOutLoading: false,
    logOutDone: false,
    logOutError: false,
    withdrawLoading: false,
    withdrawDone: false,
    withdrawError: null,
    logInCheckLoading: false,
    logInCheckDone: false,
    logInCheckError: false,
    loadUsersLoading: false,
    loadUsersDone: false,
    loadUsersError: false,
    loadUserLoading: false,
    loadUserDone: false,
    loadUserError: false,
    insertUserLoading: false,
    insertUserDone: false,
    insertUserError: false,
    deleteUserLoading: false,
    deleteUserDone: false,
    deleteUserError: false,
}

export const loginRequestAction = (data) => {
    return {
        type: LOG_IN_REQUEST,
        data,
    }
}

export const logoutRequestAction = (data) => {
    return {
        type: LOG_OUT_REQUEST,
        data,
    }
}

export const withdrawRequestAction = () => {
    return {
        type: WITHDRAW_REQUEST,
    }
}


export const loginCheckRequestAction = (data) => {
    return {
        type: LOG_IN_CHECK_REQUEST,
        data,
    }
}

export const loadUsersRequestAction = (data) => {
    return {
        type: LOAD_USERS_REQUEST,
        data,
    }
}

export const loadUserRequestAction = (data) => {
    return {
        type: LOAD_USER_REQUEST,
        data,
    }
}

export const updateUserRequestAction = (data) => {
    return {
        type: UPDATE_USER_REQUEST,
        data,
    }
}

export const insertUserRequestAction = (data) => {
    return {
        type: INSERT_USER_REQUEST,
        data
    }
}

export const deleteUserRequestAction = (data) => {
    return {
        type: DELETE_USER_REQUEST,
        data
    }
}

export const setUserInfoRequestAction = (data) => {
    return {
        type: SET_USER_INFO_REQUEST,
        data
    }
}

const reducer = (state = initialState, action) => produce(state, (draft) => {
    switch (action.type) {
        case LOG_IN_REQUEST:
            draft.logInLoading = true;
            draft.logInDone = false;
            draft.logInError = null;
            break;
        case LOG_IN_SUCCESS:
            draft.logInLoading = false;
            draft.logInDone = true;
            draft.me = action.data.data;
            break;
        case LOG_IN_FAILURE:
            draft.logInLoading = false;
            draft.logInDone = false;
            draft.logInError = action.data;
            break;
        case LOG_OUT_REQUEST:
            draft.logOutLoading = true;
            draft.logOutDone = false;
            draft.logOutError = null;
            break;
        case LOG_OUT_SUCCESS:
            draft.logOutLoading = false;
            draft.logOutDone = true;
            draft.me = {};
            break;
        case LOG_OUT_FAILURE:
            draft.logOutLoading = false;
            draft.logOutDone = false;
            draft.logOutError = action.data;
            break;
        case WITHDRAW_REQUEST:
            draft.withdrawLoading = true;
            draft.withdrawDone = false;
            draft.withdrawError = null;
            break;
        case WITHDRAW_SUCCESS:
            draft.withdrawLoading = false;
            draft.withdrawDone = true;
            draft.me = {};
            break;
        case WITHDRAW_FAILURE:
            draft.withdrawLoading = false;
            draft.withdrawDone = false;
            draft.withdrawError = action.data;
            break;
        case LOG_IN_CHECK_REQUEST:
            draft.logInCheckLoading = true;
            draft.logInCheckDone = false;
            draft.logInCheckError = null;
            break;
        case LOG_IN_CHECK_SUCCESS:
            draft.logInCheckLoading = false;
            draft.logInCheckDone = true;
            draft.me = action.data.data;
            break;
        case LOG_IN_CHECK_FAILURE:
            draft.logInCheckLoading = false;
            draft.logInCheckDone = false;
            draft.logInCheckError = action.data;
            break;
        case LOAD_USER_REQUEST:
            draft.loadUserLoading = true;
            draft.loadUserDone = false;
            draft.loadUserError = null;
            break;
        case LOAD_USER_SUCCESS:
            draft.loadUserLoading = false;
            draft.loadUserDone = true;
            draft.user = action.data.data;
            break;
        case LOAD_USER_FAILURE:
            draft.loadUserLoading = false;
            draft.loadUserDone = false;
            draft.loadUserError = action.data;
            break;
        case LOAD_USERS_REQUEST:
            draft.loadUsersLoading = true;
            draft.loadUsersDone = false;
            draft.loadUsersError = null;
            break;
        case LOAD_USERS_SUCCESS:
            draft.loadUsersLoading = false;
            draft.loadUsersDone = true;
            draft.users = action.data.data;
            break;
        case LOAD_USERS_FAILURE:
            draft.loadUsersLoading = false;
            draft.loadUsersDone = false;
            draft.loadUsersError = action.data;
            break;
        case INSERT_USER_REQUEST:
            draft.insertUserLoading = true;
            draft.insertUserDone = false;
            draft.insertUserError = null;
            break;
        case INSERT_USER_SUCCESS:
            draft.insertUserLoading = false;
            draft.insertUserDone = true;
            break;
        case INSERT_USER_FAILURE:
            draft.insertUserLoading = false;
            draft.insertUserDone = false;
            draft.insertUserError = action.data;
            break;
        case UPDATE_USER_REQUEST:
            draft.updateUserLoading = true;
            draft.updateUserDone = false;
            draft.updateUserError = null;
            break;
        case UPDATE_USER_SUCCESS:
            draft.updateUserLoading = false;
            draft.updateUserDone = true;
            break;
        case UPDATE_USER_FAILURE:
            draft.updateUserLoading = false;
            draft.updateUserDone = false;
            draft.updateUserError = action.data;
            break;
        case DELETE_USER_REQUEST:
            draft.deleteUserLoading = true;
            draft.deleteUserDone = false;
            draft.deleteUserError = null;
            break;
        case DELETE_USER_SUCCESS:
            draft.deleteUserLoading = false;
            draft.deleteUserDone = true;
            break;
        case DELETE_USER_FAILURE:
            draft.deleteUserLoading = false;
            draft.deleteUserDone = false;
            draft.deleteUserError = action.data;
            break;
        case SET_USER_INFO_REQUEST:
            draft.info = action.data;
            break;
    }
});

export default reducer;


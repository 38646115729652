import styled from "styled-components";
import {useEffect} from "react";

export const QuizContent3Item = ({data, choose, number}) => {
    let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0, target = null;

    useEffect(() => {
        if (choose[number]) {
            const chooseSplit = choose[number].split(' ');

            for (let i = 0; i < chooseSplit.length; i++) {
                const el = document.getElementsByClassName(`${chooseSplit[i]}`);
                const dummyEl = document.querySelectorAll('[name=dummy]');

                if (dummyEl[i]) {
                    if (el.length > 1) {
                        if (el[0].style.left !== '') {
                            el[1].style.left = `${dummyEl[i].offsetLeft}px`;
                            el[1].style.top = `${dummyEl[i].offsetTop}px`;
                        } else {
                            el[0].style.left = `${dummyEl[i].offsetLeft}px`;
                            el[0].style.top = `${dummyEl[i].offsetTop}px`;
                        }
                    } else {
                        if (el[0]) {
                            el[0].style.left = `${dummyEl[i].offsetLeft}px`;
                            el[0].style.top = `${dummyEl[i].offsetTop}px`;
                        }
                    }
                }
            }
        }
    }, [data, choose]);

    const onCardMouseStart = (e) => {
        e.target.style.cursor = 'grabbing';
        pos3 = e.clientX;
        pos4 = e.clientY;
        target = e;

        e.target.onmouseup = onCardMouseEnd;
        document.addEventListener('mousemove', onCardMouseMove)
    };

    const onCardMouseMove = (event) => {
        const e = target;
        pos1 = pos3 - event.clientX;
        pos2 = pos4 - event.clientY;
        pos3 = event.clientX;
        pos4 = event.clientY;

        const el = e.target;
        el.style.top = (el.offsetTop - pos2) + "px";
        el.style.left = (el.offsetLeft - pos1) + "px";
    };

    const onCardMouseEnd = (e) => {
        e.target.style.cursor = '';
        pos3 = e.clientX;
        pos4 = e.clientY;

        const blankEl = document.querySelectorAll('[name=dummy]');
        const blankData = [];

        blankEl.forEach((el) => {
            blankData.push({
                value: el.getAttribute('value'),
                x: el.getBoundingClientRect().x,
                y: el.getBoundingClientRect().y,
            });
        });

        let stop = false;

        blankData.map((blank) => {
            if (blank.x - 10 < e.target.getBoundingClientRect().x && e.target.getBoundingClientRect().x < blank.x + 10) {
                if (blank.y - 10 < e.target.getBoundingClientRect().y && e.target.getBoundingClientRect().y < blank.y + 10) {
                    stop = true;
                }
            }
        });

        if (!stop) {
            e.target.style.left = '';
            e.target.style.top = '';
        }

        document.removeEventListener('mousemove', onCardMouseMove);
    };

    return (
        <QuizContentWrap>
            <TitleWrap>
                {data.title}
            </TitleWrap>

            <QuestionWrap>
                {data.question}
            </QuestionWrap>

            <DummyAnswerWrap>
                <DummyWrap>
                    {
                        data.answer.map((i, index) => <DummyItem index={index} name={'dummy'} key={index}>&nbsp;</DummyItem>)
                    }
                </DummyWrap>

                <AnswerWrap>
                    {
                        data.answer.map((i, index) => <AnswerItem onMouseDown={onCardMouseStart} index={index} name={'answer'} className={i}
                                                                  key={index}>{i}</AnswerItem>)
                    }
                </AnswerWrap>
            </DummyAnswerWrap>
        </QuizContentWrap>
    )
}

const QuizContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  gap: 80px;
  padding-top: 20px;
`

const TitleWrap = styled.div`
  font-weight: bold;
  font-size: 24px;
`

const QuestionWrap = styled.div`
  font-size: 32px;
`

const DummyWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 30px;
  font-size: 28px;
`

const DummyItem = styled.div`
  position: absolute;
  left: ${({index}) => `calc(${(208 * (index % 5))}px)`};
  top: ${({index}) => `${(80 * (index < 5 ? 0 : 1))}px`};
  width: 188px;
  text-align: center;
  padding: 10px;
  background-color: #eee;
  border: 1px solid #eee;
  border-radius: 12px;
`

const AnswerWrap = styled.div`
  width: 100%;
  font-size: 28px;
  min-height: 85px;
  cursor: grab;
`

const AnswerItem = styled.div`
  position: absolute;
  left: ${({index}) => `calc(${(208 * (index % 5))}px)`};
  top: ${({index}) => `${(80 * (index < 5 ? 2 : 3))}px`};
  width: 188px;
  text-align: center;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #CC7EB2;
  box-shadow: rgb(0 0 0 / 15%) 0 0 6px 0;
  border-radius: 12px;
`

const DummyAnswerWrap = styled.div`
  position: relative;
  width: 100%;
`

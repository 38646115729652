import Label from "../../atoms/label";
import styled from "styled-components";
import {useCallback} from "react";

export const QuizHeaderItem = ({subject, total, number, category}) => {

    const onQuizProgressBar = useCallback(() => {
        const result = [];

        for (let i = 0; i < total; i++) {
            result.push(<QuizProgressBar active={i < number} first={i === 0} last={(i + 1) === total} key={i}/>)
        }

        return result;
    }, [number]);

    return (
        <QuizHeaderWrap>
            <Label fontSize={'38px'} fontWeight={'500'}> {subject} </Label>

            <QuizProgressBarWrap>
                {onQuizProgressBar()}
            </QuizProgressBarWrap>

            <StepStatusWrap>
                <CircleNumber>
                    {number}
                </CircleNumber>

                <span> {category} </span>
            </StepStatusWrap>
        </QuizHeaderWrap>
    )
}


const QuizHeaderWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  gap: 15px;
  text-align: center;
`

const QuizProgressBarWrap = styled.div`
  width: 100%;
  display: flex;
`

const QuizProgressBar = styled.div`
  height: 12px;
  width: 100%;
  background-color: ${({active}) => active ? '#CC7EB2' : '#eee'};
  border-top-left-radius: ${({first}) => first ? '6px' : ''};
  border-bottom-left-radius: ${({first}) => first ? '6px' : ''};
  border-top-right-radius: ${({last}) => last ? '6px' : ''};
  border-bottom-right-radius: ${({last}) => last ? '6px' : ''};

  @media screen and (max-width: 768px) {
    height: 6px;
  }
`;

const CircleNumber = styled.div`
  width: 44px;
  height: 44px;
  border-radius: 100%;
  color: #fff;
  background-color: #CC7EB2;
  font-size: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StepStatusWrap = styled.div`
  display: flex;
  gap: 10px;
  font-size: 28px;
  color: #CC7EB2;
  align-items: center;
  font-weight: bold;
`

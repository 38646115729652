import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {useCallback} from "react";
import {showModalRequestAction} from "../../../redux/modal/reducer";

const Modal = () => {
    const {showModal, title, content} = useSelector((state) => state.modal);
    const dispatch = useDispatch();

    const onClose = useCallback(() => {
        dispatch(showModalRequestAction({showModal: false}));
    }, []);

    if (showModal) return (
        <Wrap>
            <ModalWrap>
                <ModalCloseWrap onClick={onClose}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                         strokeWidth="1.5"
                         strokeLinecap="round" strokeLinejoin="round" className="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"/>
                        <line x1="6" y1="6" x2="18" y2="18"/>
                    </svg>
                </ModalCloseWrap>

                <ModalTitleWrap>
                    {title}
                </ModalTitleWrap>

                {content && content}

                {/*<ModalContentWrap dangerouslySetInnerHTML={{__html: content}}>*/}
                {/*</ModalContentWrap>*/}
            </ModalWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255,0.3);
`

const ModalWrap = styled.div`
  width: 400px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 15%) 0 0 6px 0;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 10;
`

const ModalTitleWrap = styled.div`
  display: flex;
  align-items: center;
  font-size: 44px;
  font-weight: bold;
  justify-content: center;
`

const ModalContentWrap = styled.div`
  overflow: auto;
`

const ModalCloseWrap = styled.div`
  position: absolute;
  cursor: pointer;
  right: 20px;
`

export default Modal;

import {unstable_HistoryRouter as HistoryRouter, Routes, Route} from "react-router-dom";
import history from "../../utils/history";
import {useDispatch} from "react-redux";
import "../../assets/css/common.css";
import {useCallback, useEffect} from "react";
import {loginCheckRequestAction} from "../../redux/user/reducer";
import {Home} from "../home";
import {Result} from "../result";
import {QuizPreTestA} from "../quiz/preTestA";
import {QuizPreTestB} from "../quiz/preTestB";
import {QuizBeginner} from "../quiz/beginner";
import {QuizStarter} from "../quiz/starter";
import {QuizBasic} from "../quiz/basic";
import {QuizIntermediate} from "../quiz/intermediate";
import {QuizAdvanced} from "../quiz/advanced";
import {ResultViewer} from "../result/viewer";
import {PreTestResultViewer} from "../result/preTestViewer";

const App = () => {
    const dispatch = useDispatch();
    const loginCheck = useCallback(() => {
        dispatch(loginCheckRequestAction());
    }, []);

    history.listen(() => {
        loginCheck();
    });

    useEffect(() => {
        loginCheck();
    }, []);

    return (
        <HistoryRouter history={history}>
            <Routes>
                <Route path="/" element={<Home />}/>

                <Route path="/quiz/preTestA" element={<QuizPreTestA />}/>
                <Route path="/quiz/preTestB" element={<QuizPreTestB />}/>
                <Route path="/quiz/beginner" element={<QuizBeginner />}/>
                <Route path="/quiz/starter" element={<QuizStarter />}/>
                <Route path="/quiz/basic" element={<QuizBasic />}/>
                <Route path="/quiz/intermediate" element={<QuizIntermediate />}/>
                <Route path="/quiz/advanced" element={<QuizAdvanced />}/>

                <Route path="/result" element={<Result />}/>
                <Route path="/viewer" element={<ResultViewer />}/>
                <Route path="/preTestViewer" element={<PreTestResultViewer />}/>
            </Routes>
        </HistoryRouter>
    );
}

export default App;

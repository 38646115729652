import {
    INSERT_REPORT_FAILURE,
    INSERT_REPORT_REQUEST,
    INSERT_REPORT_SUCCESS,
} from "./constants";
import produce from "immer";

const initialState = {
    insertReportLoading: false,
    insertReportDone: false,
    insertReportError: false,
}

export const insertReportRequestAction = (data) => {
    return {
        type: INSERT_REPORT_REQUEST,
        data
    }
}

const reducer = (state = initialState, action) => produce(state, (draft) => {
    switch (action.type) {
        case INSERT_REPORT_REQUEST:
            draft.insertReportLoading = true;
            draft.insertReportDone = false;
            draft.insertReportError = null;
            break;
        case INSERT_REPORT_SUCCESS:
            draft.insertReportLoading = false;
            draft.insertReportDone = true;
            break;
        case INSERT_REPORT_FAILURE:
            draft.insertReportLoading = false;
            draft.insertReportDone = false;
            draft.insertReportError = action.data;
            break;
    }
});

export default reducer;


import Select from "../../atoms/select";
import styled from "styled-components";
import {useCallback} from "react";

export const SelectBirth = ({onInfoChange, info}) => {

    const yearData = useCallback(() => {
        const result = [];
        for (let i = 2024; i > 1900; i--) {
            result.push({
                value: i,
                text: i
            });
        }

        return result;
    }, []);

    const monthData = useCallback(() => {
        const result = [];
        for (let i = 1; i < 13; i++) {
            result.push({
                value: i,
                text: i
            });
        }

        return result;
    }, []);

    const dayData = useCallback(() => {
        const result = [];
        for (let i = 1; i < 32; i++) {
            result.push({
                value: i,
                text: i
            });
        }

        return result;
    }, []);


    return (
        <Wrap>
            <Select theme={'normal'} name={'birth1'} essential={false} onChange={onInfoChange} value={info.birth1} options={yearData()} />
            <Select theme={'normal'} name={'birth2'} essential={false} onChange={onInfoChange} value={info.birth2} options={monthData()} />
            <Select theme={'normal'} name={'birth3'} essential={false} onChange={onInfoChange} value={info.birth3} options={dayData()} />
        </Wrap>
    )
}

const Wrap = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;

  & > select {
    width: 100%;
  }
`


import styled from "styled-components";
import {QuizTemplates} from "../../components/templates/quiz";
import {useEffect, useState} from "react";
import {quizData} from "../../constants/quizData";

export const QuizPreTestA = () => {
    const [index, setIndex] = useState(Number(localStorage.getItem('index')) || 0);
    const [choose, setChoose] = useState(JSON.parse(localStorage.getItem('choose')) || {});
    const [quiz, setQuiz] = useState(quizData.find(i => i.subject === 'preTestA'));

    useEffect(() => {
        localStorage.setItem('index', index);
    }, [index]);

    useEffect(() => {
        localStorage.setItem('choose', JSON.stringify(choose));
    }, [choose]);

    return (
        <QuizWrap>
            <QuizTemplates data={quiz.data[index]} subject={'Pre-test A'} answer={quiz.answer} total={quiz.data.length} index={index} setIndex={setIndex} choose={choose} setChoose={setChoose} />
        </QuizWrap>
    )
}

const QuizWrap = styled.div`
  max-width: 1100px;
  margin: 0 auto;
`

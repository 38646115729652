import styled from "styled-components";

export const QuizContent4Item = ({data, number, choose, setChoose}) => {
    return (
        <QuizContentWrap>
            <QuestionWrap dangerouslySetInnerHTML={{__html: data.question}} isLong={data.isLong} />

            <TitleWrap dangerouslySetInnerHTML={{__html: data.title.replaceAll('{blank}', "<span style='display: inline-block; width: 100px; height: 1px; border-bottom: 1px solid #000; opacity: 0.3'></span>")}}>

            </TitleWrap>

            <AnswerWrap isLong={data.isLong}>
                {
                    data.answer.map((i, index) => <AnswerItem active={choose[number] === i} onClick={() => setChoose((prev) => ({...prev, [number]: i}))} key={index}>{i}</AnswerItem>)
                }
            </AnswerWrap>
        </QuizContentWrap>
    )
}

const QuizContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  gap: 35px;
`

const TitleWrap = styled.div`
  font-weight: bold;
  font-size: 24px;
`

const QuestionWrap = styled.div`
  background-color: #eee;
  border-radius: 12px;
  padding: 30px;
  font-size: ${({isLong}) => isLong ? '24px' : '32px'};
  line-height: ${({isLong}) => isLong ? '40px' : '50px'};
  word-break: break-word;
`

const AnswerWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  font-size: ${({isLong}) => isLong ? '24px' : '32px'};
`

const AnswerItem = styled.div`
  width: 100%;
  text-align: center;
  padding: 20px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({active}) => active ? '#CC7EB2' : '#eee'};
  opacity: ${({active}) => active ? '1' : '0.5'};
  transition: opacity 0.1s;
  box-shadow: rgb(0 0 0 / 15%) 0 0 6px 0;
  border-radius: 12px;
  cursor: pointer;
`

import axios from "axios";
import {
    INSERT_REPORT_FAILURE,
    INSERT_REPORT_REQUEST,
    INSERT_REPORT_SUCCESS,
} from "./constants";
import {all, fork, takeLatest, call, put, throttle} from 'redux-saga/effects';

function insertReportAPI(data) {
    return axios.post('/vendor/api/v1/report', data)
}

function* insertReport(action) {
    try {
        const result = yield call(insertReportAPI, action.data);
        yield put({
            type: INSERT_REPORT_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: INSERT_REPORT_FAILURE,
            data: err.response.data,
        });
        alert(err.response.data.data.message);
    }
}

function* watchInsertReport() {
    yield throttle(1000, INSERT_REPORT_REQUEST, insertReport);
}

export default function* saga() {
    yield all([
        fork(watchInsertReport),
    ])
}


import {combineReducers} from "redux";
import user from "./redux/user/reducer";
import alert from "./redux/alert/reducer";
import modal from "./redux/modal/reducer";
import layout from "./redux/layout/reducer";
import report from "./redux/report/reducer";

const rootReducer = (state, action) => {
    switch (action.type) {
        default: {
            const combinedReducer = combineReducers({
                user,
                alert,
                layout,
                modal,
                report,
            });
            return combinedReducer(state, action);
        }
    }
};

export default rootReducer;

export const scoreData = [
    {
        subject: 'preTestA',
        vocabulary: 10,
        reading: 10,
        speaking: 10,
        sentence: 10,
        grammar: 10,
    },
    {
        subject: 'preTestB',
        vocabulary: 10,
        reading: 10,
        speaking: 10,
        sentence: 10,
        grammar: 10,
    },
    {
        subject: 'beginner',
        vocabulary: 2,
        speaking: 4,
        sentence: 4,
        grammar: 4,
    },
    {
        subject: 'starter',
        vocabulary: 1,
        speaking: 3,
        reading: 3,
        sentence: 3,
        grammar: 3,
    },
    {
        subject: 'basic',
        vocabulary: 1,
        speaking: 3,
        reading: 3,
        sentence: 3,
        grammar: 3,
    },
    {
        subject: 'intermediate',
        vocabulary: 1,
        speaking: 3,
        reading: 3,
        sentence: 3,
        grammar: 3,
    },
    {
        subject: 'advanced',
        vocabulary: 1,
        speaking: 3,
        reading: 3,
        sentence: 3,
        grammar: 3,
    }
];

import axios from "axios";
import {
    DELETE_USER_FAILURE,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    INSERT_USER_FAILURE,
    INSERT_USER_REQUEST,
    INSERT_USER_SUCCESS,
    LOAD_USER_FAILURE,
    LOAD_USER_REQUEST,
    LOAD_USER_SUCCESS,
    LOAD_USERS_FAILURE,
    LOAD_USERS_REQUEST,
    LOAD_USERS_SUCCESS,
    LOG_IN_CHECK_FAILURE,
    LOG_IN_CHECK_REQUEST,
    LOG_IN_CHECK_SUCCESS,
    LOG_IN_FAILURE,
    LOG_IN_REQUEST,
    LOG_IN_SUCCESS,
    LOG_OUT_FAILURE,
    LOG_OUT_REQUEST,
    LOG_OUT_SUCCESS,
    UPDATE_USER_FAILURE,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    WITHDRAW_FAILURE, WITHDRAW_REQUEST,
    WITHDRAW_SUCCESS
} from "./constants";
import {all, fork, takeLatest, call, put, throttle} from 'redux-saga/effects';

function logInAPI(data) {
    return axios.post('/vendor/api/v1/user/login', data)
}

function logOutAPI(data) {
    return axios.post('/vendor/api/v1/user/logout', data)
}

function withdrawAPI() {
    return axios.post('/vendor/api/v1/user/withdraw');
}

function logInCheckAPI() {
    return axios.post('/vendor/api/v1/user/login/check');
}

function loadUsersAPI() {
    return axios.get('/vendor/api/v1/user')
}

function loadUserAPI(data) {
    return axios.get(`/vendor/api/v1/user/${data.seq}`)
}

function insertUserAPI(data) {
    return axios.post('/vendor/api/v1/user/join', data)
}

function updateUserAPI(data) {
    return axios.put('/vendor/api/v1/user', data);
}

function deleteUserAPI(data) {
    return axios.delete('/vendor/api/v1/user', {data})
}

function* logIn(action) {
    try {
        const result = yield call(logInAPI, action.data);
        yield put({
            type: LOG_IN_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: LOG_IN_FAILURE,
            data: err.response.data,
        });
    }
}

function* logOut(action) {
    try {
        const result = yield call(logOutAPI, action.data);

        yield put({
            type: LOG_OUT_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: LOG_OUT_FAILURE,
            data: err.response.data,
        });
    }
}

function* withdraw() {
    try {
        const result = yield call(withdrawAPI);
        yield put({
            type: WITHDRAW_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: WITHDRAW_FAILURE,
            data: err.response?.data,
        });
    }
}


function* logInCheck(action) {
    try {
        const result = yield call(logInCheckAPI, action.data);
        yield put({
            type: LOG_IN_CHECK_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: LOG_IN_CHECK_FAILURE,
            data: err.response.data,
        });
    }
}

function* loadUsers(action) {
    try {
        const result = yield call(loadUsersAPI, action.data);
        yield put({
            type: LOAD_USERS_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: LOAD_USERS_FAILURE,
            data: err.response.data,
        });
    }
}

function* loadUser(action) {
    try {
        const result = yield call(loadUserAPI, action.data);
        yield put({
            type: LOAD_USER_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: LOAD_USER_FAILURE,
            data: err.response.data,
        });
    }
}

function* insertUser(action) {
    try {
        const result = yield call(insertUserAPI, action.data);
        yield put({
            type: INSERT_USER_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: INSERT_USER_FAILURE,
            data: err.response.data,
        });
        alert(err.response.data.data.message);
    }
}

function* updateUser(action) {
    try {
        const result = yield call(updateUserAPI, action.data);
        yield put({
            type: UPDATE_USER_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: UPDATE_USER_FAILURE,
            data: err.response.data,
        });
    }
}

function* deleteUser(action) {
    try {
        const result = yield call(deleteUserAPI, action.data);
        yield put({
            type: DELETE_USER_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: DELETE_USER_FAILURE,
            data: err.response.data,
        });
    }
}

function* watchLogIn() {
    yield throttle(1000, LOG_IN_REQUEST, logIn);
}

function* watchLogOut() {
    yield throttle(1000, LOG_OUT_REQUEST, logOut);
}

function* watchWithdraw() {
    yield throttle(1000, WITHDRAW_REQUEST, withdraw);
}

function* watchLogInCheck() {
    yield takeLatest(LOG_IN_CHECK_REQUEST, logInCheck);
}

function* watchLoadUsers() {
    yield takeLatest(LOAD_USERS_REQUEST, loadUsers);
}

function* watchLoadUser() {
    yield takeLatest(LOAD_USER_REQUEST, loadUser);
}

function* watchInsertUser() {
    yield throttle(1000, INSERT_USER_REQUEST, insertUser);
}

function* watchUpdateUser() {
    yield throttle(1000, UPDATE_USER_REQUEST, updateUser);
}

function* watchDeleteUser() {
    yield throttle(1000, DELETE_USER_REQUEST, deleteUser);
}

export default function* saga() {
    yield all([
        fork(watchLogIn),
        fork(watchLogOut),
        fork(watchWithdraw),
        fork(watchLogInCheck),
        fork(watchLoadUsers),
        fork(watchLoadUser),
        fork(watchInsertUser),
        fork(watchUpdateUser),
        fork(watchDeleteUser),
    ])
}


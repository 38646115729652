import Input from "../../atoms/input";
import Button from "../../atoms/button";
import styled from "styled-components";
import {useCallback} from "react";
import {useDispatch} from "react-redux";
import {logoutRequestAction, withdrawRequestAction} from "../../../redux/user/reducer";
import Label from "../../atoms/label";

export const MyModalItem = ({setViewMyModal, me}) => {
    const dispatch = useDispatch();

    const onClose = useCallback(() => {
        setViewMyModal(false);
    }, []);

    const onWithdraw = useCallback(() => {
        if (window.confirm('회원탈퇴시 모든 정보가 사라집니다, 회원탈퇴 하시겠습니까?')) {
            dispatch(withdrawRequestAction());
            setViewMyModal(false);
        }
    }, []);

    const onLogout = useCallback(() => {
        dispatch(logoutRequestAction());
        setViewMyModal(false);
    }, []);

    return (
        <Wrap>
            <ModalWrap>
                <ModalCloseWrap onClick={onClose}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                         strokeWidth="1.5"
                         strokeLinecap="round" strokeLinejoin="round" className="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"/>
                        <line x1="6" y1="6" x2="18" y2="18"/>
                    </svg>
                </ModalCloseWrap>

                <ModalTitleWrap>
                    내정보
                </ModalTitleWrap>

                <ModalContentWrap>
                    <ModalInputItem>
                        <Label>아이디</Label>
                        <Input type={'text'} theme={'normal'} size={'sm'} name={'id'} disabled={true} value={me.id}></Input>
                    </ModalInputItem>

                    <ModalInputItem>
                        <Label>이메일</Label>
                        <Input type={'text'} theme={'normal'} size={'sm'} name={'id'} disabled={true} value={me.email}></Input>
                    </ModalInputItem>

                    <ModalBottomWrap>
                        <Button color={'main'} onClick={onLogout} size={'xs'}> 로그아웃 </Button>
                        <Button color={'main'} onClick={onWithdraw} size={'xs'}> 회원탈퇴 </Button>
                    </ModalBottomWrap>
                </ModalContentWrap>
            </ModalWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.3);
`

const ModalWrap = styled.div`
  width: 300px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 15%) 0 0 6px 0;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 10;
`

const ModalTitleWrap = styled.div`
  display: flex;
  align-items: center;
  font-size: 28px;
  font-weight: bold;
  justify-content: center;
`

const ModalContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const ModalCloseWrap = styled.div`
  position: absolute;
  cursor: pointer;
  right: 20px;
`

const ModalInputItem = styled.div`
  font-size: 14px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
`

const ModalBottomWrap = styled.div`
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > button {
    width: 100%;
  }
`;

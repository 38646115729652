import Input from "../../atoms/input";
import Button from "../../atoms/button";
import styled from "styled-components";
import {useCallback, useEffect, useState} from "react";
import history from "../../../utils/history";
import {useDispatch} from "react-redux";
import {insertUserRequestAction, setUserInfoRequestAction} from "../../../redux/user/reducer";
import Label from "../../atoms/label";

export const JoinModalItem = ({setViewJoinModal}) => {
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);
    const [info, setInfo] = useState({
        id: '',
        email: '',
        password: '',
        passwordCheck: '',
        name: '',
    });

    const onClose = useCallback(() => {
        setViewJoinModal(false);
    }, []);

    const onInfoChange = useCallback((e) => {
        setInfo((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    }, []);

    const onDisabled = useCallback(() => {
        let stop = false;

        for (let i of Object.keys(info)) {
            if (!info[i]) stop = true;
        }

        setDisabled(stop);
    }, [info]);

    const onSubmit = useCallback(() => {
        if (!info.id || !info.password || !info.passwordCheck || !info.name || !info.email) {
            alert('모든 내용을 입력해주세요.');
            return false;
        }

        if (info.password !== info.passwordCheck) {
            alert('비밀번호가 맞지 않습니다.');
            return false;
        }

        if (!/^[a-z]+[a-z0-9]{3,20}$/.test(info.id)) {
            alert('아이디 형식이 맞지 않습니다.');
            return false;
        }

        if (!/^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+]).{6,20}$/.test(info.password)) {
            alert('패스워드 형식이 맞지 않습니다.');
            return false;
        }

        if (!/^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/.test(info.email)) {
            alert('이메일 형식이 맞지 않습니다.');
            return false;
        }

        if (!/^(?=.*[a-z0-9가-힣])[a-z0-9가-힣]{2,8}$/.test(info.name)) {
            alert('닉네임 형식이 맞지 않습니다.');
            return false;
        }

        dispatch(insertUserRequestAction({...info}));
        // setViewJoinModal(false);
    }, [info]);

    useEffect(() => {
        onDisabled();
    }, [info])

    return (
        <Wrap>
            <ModalWrap>
                <ModalCloseWrap onClick={onClose}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                         strokeWidth="1.5"
                         strokeLinecap="round" strokeLinejoin="round" className="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"/>
                        <line x1="6" y1="6" x2="18" y2="18"/>
                    </svg>
                </ModalCloseWrap>

                <ModalTitleWrap>
                    회원가입
                </ModalTitleWrap>

                <ModalContentWrap>
                    <ModalInputItem>
                        <Label>아이디</Label>
                        <Input type={'text'} theme={'normal'} size={'sm'} name={'id'} placeholder={'영문과 숫자를 포함한 5 ~ 11자'} value={info.id} onChange={onInfoChange}></Input>
                        <Label fontSize={'13px'} color={'#999'}>사용자 ID는 3~20자 사이의 영문 또는 숫자로 이루어져야 하며 영문으로 시작되어야 합니다.</Label>
                    </ModalInputItem>

                    <ModalInputItem>
                        <Label>비밀번호</Label>
                        <Input type={'password'} theme={'normal'} size={'sm'} name={'password'} placeholder={'영문, 숫자, 특수문자 조합 최소 8자'} value={info.password} onChange={onInfoChange}></Input>
                        <Input type={'password'} theme={'normal'} size={'sm'} name={'passwordCheck'} placeholder={'비밀번호 재입력'} value={info.passwordCheck} onChange={onInfoChange}></Input>
                        <Label fontSize={'13px'} color={'#999'}>비밀번호는 6~20자 사이의 영문+숫자+특수문자로 이루어져야 합니다.</Label>
                    </ModalInputItem>

                    <ModalInputItem>
                        <Label>이메일</Label>
                        <Input type={'text'} theme={'normal'} size={'sm'} name={'email'} placeholder={'이메일을 입력하세요.'} value={info.email} onChange={onInfoChange}></Input>
                        <Label fontSize={'13px'} color={'#999'}>메일 주소는 메일 인증 후 비밀번호 변경이나 찾기 등에 사용됩니다.</Label>
                    </ModalInputItem>

                    <ModalInputItem>
                        <Label>닉네임</Label>
                        <Input type={'text'} theme={'normal'} size={'sm'} name={'name'} placeholder={'닉네임을 입력하세요'} value={info.name} onChange={onInfoChange}></Input>
                        <Label fontSize={'13px'} color={'#999'}>닉네임은 2~8자 이내여야 합니다.</Label>
                    </ModalInputItem>

                    <ModalEtcWrap>
                        <Label> <strong>이용약관</strong>, <strong>개인정보 수집 및 이용 내용</strong> 을 확인하였고 동의합니다.</Label>
                        <Button color={'main'} onClick={onSubmit} size={'xs'} disabled={disabled}> 동의하기 가입하기 </Button>
                    </ModalEtcWrap>
                </ModalContentWrap>
            </ModalWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.3);
`

const ModalWrap = styled.div`
  width: 300px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 15%) 0 0 6px 0;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 10;
`

const ModalTitleWrap = styled.div`
  display: flex;
  align-items: center;
  font-size: 28px;
  font-weight: bold;
  justify-content: center;
`

const ModalContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const ModalCloseWrap = styled.div`
  position: absolute;
  cursor: pointer;
  right: 20px;
`

const ModalInputItem = styled.div`
  font-size: 14px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
`

const ModalEtcWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  & strong {
    color: #CC7EB2;
    text-decoration: underline;
  }
`

import styled from "styled-components";

const Select = ({essential = true, options, margin, onChange, ...props}) => {
    return (
        <SelectWrap margin={margin} onChange={onChange} {...props}>
            {
                !essential && <option value={''}> </option>
            }

            {
                options.map((v, index) => {
                    return <option key={index} value={v.value}> {v.text} </option>
                })
            }
        </SelectWrap>
    )
}

const SelectWrap = styled.select`
  margin: ${({margin}) => margin};
  max-width: ${({maxWidth}) => maxWidth };
  max-height: ${({maxHeight}) => maxHeight };
  padding: 0 10px;
  border: 1px solid #eee;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s;
  outline: 0;
  height: 45px;
  
  &:focus {
    border-color: #CC7EB2;
  }
`

export default Select;

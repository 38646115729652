import {QuizHeaderItem} from "../../oraganisms/quiz/header";
import {QuizContent3Item} from "../../oraganisms/quiz/content/3";
import {QuizFooterItem} from "../../oraganisms/quiz/footer";
import styled from "styled-components";
import {QuizContent1Item} from "../../oraganisms/quiz/content/1";
import {QuizContent2Item} from "../../oraganisms/quiz/content/2";
import {QuizContent4Item} from "../../oraganisms/quiz/content/4";

export const QuizTemplates = ({data, answer, total, subject, index, setIndex, choose, setChoose}) => {
    return (
        <QuizWrap>
            <QuizHeaderItem subject={subject} total={total} number={data.number} category={data.category} />

            {
                data.type === 1 && <QuizContent1Item data={data} choose={choose} setChoose={setChoose} number={data.number} />
            }

            {
                data.type === 2 && <QuizContent2Item data={data} choose={choose} setChoose={setChoose} number={data.number} />
            }

            {
                data.type === 3 && <QuizContent3Item data={data} choose={choose} setChoose={setChoose} number={data.number} />
            }

            {
                data.type === 4 && <QuizContent4Item data={data} choose={choose} setChoose={setChoose} number={data.number} />
            }

            <QuizFooterItem type={data.type} answer={answer} total={total} index={index} setIndex={setIndex} choose={choose} setChoose={setChoose} />
        </QuizWrap>
    )
}

const QuizWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  height: 100vh;
  padding: 0 40px;
`

import Input from "../../atoms/input";
import Button from "../../atoms/button";
import styled from "styled-components";
import {useCallback, useEffect, useState} from "react";
import history from "../../../utils/history";
import {useDispatch} from "react-redux";
import {setUserInfoRequestAction} from "../../../redux/user/reducer";
import {SelectBirth} from "../../molecules/selectBirth";

export const PreTestModalItem = ({setViewTestModal}) => {
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);
    const [info, setInfo] = useState({
        name: '',
        school: '',
        grade: '',
        birth1: '',
        birth2: '',
        birth3: '',
    });

    const onClose = useCallback(() => {
        setViewTestModal(false);
    }, []);

    const goPreTestA = useCallback(() => {
        dispatch(setUserInfoRequestAction(info));
        history.push('/quiz/preTestA');
        localStorage.setItem('info', JSON.stringify(info));
        localStorage.setItem('index', 0);
        localStorage.setItem('choose', '{}');
        setViewTestModal(false);
    }, [info]);

    const goPreTestB = useCallback(() => {
        dispatch(setUserInfoRequestAction(info));
        history.push('/quiz/preTestB');
        localStorage.setItem('info', JSON.stringify(info));
        localStorage.setItem('index', 0);
        localStorage.setItem('choose', '{}');
        setViewTestModal(false);
    }, [info]);

    const onInfoChange = useCallback((e) => {
        setInfo((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    }, []);

    const onDisabled = useCallback(() => {
        let stop = false;

        for (let i of Object.keys(info)) {
            if (!info[i]) stop = true;
        }

        setDisabled(stop);
    }, [info]);

    useEffect(() => {
        onDisabled();
    }, [info]);

    return (
        <Wrap>
            <ModalWrap>
                <ModalCloseWrap onClick={onClose}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                         strokeWidth="1.5"
                         strokeLinecap="round" strokeLinejoin="round" className="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"/>
                        <line x1="6" y1="6" x2="18" y2="18"/>
                    </svg>
                </ModalCloseWrap>

                <ModalTitleWrap>
                    Pre Test
                </ModalTitleWrap>

                <ModalContentWrap>
                    <ModalInputItem>
                        <span>Name</span>
                        <Input theme={'normal'} name={'name'} onChange={onInfoChange} value={info.name}/>
                    </ModalInputItem>

                    <ModalInputItem>
                        <span>School</span>
                        <Input theme={'normal'} name={'school'} onChange={onInfoChange} value={info.school}/>
                    </ModalInputItem>

                    <ModalInputItem>
                        <span>Grade</span>
                        <Input theme={'normal'} type={'number'} name={'grade'} onChange={onInfoChange} value={info.grade}/>
                    </ModalInputItem>

                    <ModalInputItem>
                        <span>Birth</span>
                        <SelectBirth onInfoChange={onInfoChange} info={info} />
                    </ModalInputItem>

                    <ModalBottomWrap>
                        <Button color={'main'} onClick={goPreTestA} size={'xs'} disabled={disabled}> Pre-test A </Button>
                        <Button color={'main'} onClick={goPreTestB} size={'xs'} disabled={disabled}> Pre-test B </Button>
                    </ModalBottomWrap>
                </ModalContentWrap>
            </ModalWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255,0.3);
`

const ModalWrap = styled.div`
  width: 300px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 15%) 0 0 6px 0;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 10;
`

const ModalTitleWrap = styled.div`
  display: flex;
  align-items: center;
  font-size: 28px;
  font-weight: bold;
  justify-content: center;
`

const ModalContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const ModalCloseWrap = styled.div`
  position: absolute;
  cursor: pointer;
  right: 20px;
`

const ModalInputItem = styled.div`
  font-size: 14px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
`

const ModalBirthItem = styled.div`
  font-size: 14px;
  gap: 10px;
  display: flex;
`

const ModalBottomWrap = styled.div`
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

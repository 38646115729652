import {all, fork} from 'redux-saga/effects';
import user from "./redux/user/saga";
import report from "./redux/report/saga";

export default function* rootSaga() {
    yield all([
        fork(user),
        fork(report),
    ])
}

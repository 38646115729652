import {
    FOLD_LAYOUT_LEFT_REQUEST
} from "./constants";
import produce from "immer";

const initialState = {
    fold: false,
}

export const foldLayoutLeftRequestAction = (data) => {
    return {
        type: FOLD_LAYOUT_LEFT_REQUEST,
        data,
    }
}

const reducer = (state = initialState, action) => produce(state, (draft) => {
    switch (action.type) {
        case FOLD_LAYOUT_LEFT_REQUEST:
            draft.fold = !draft.fold;
            break;
    }
});

export default reducer;


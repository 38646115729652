import background from "../../assets/images/background.png";
import styled from "styled-components";
import {useCallback, useState} from "react";
import {PreTestModalItem} from "../../components/oraganisms/home/PreTestModal";
import Button from "../../components/atoms/button";
import {LoginModalItem} from "../../components/oraganisms/home/LoginModal";
import {JoinModalItem} from "../../components/oraganisms/home/JoinModal";
import {shallowEqual, useSelector} from "react-redux";
import {MyModalItem} from "../../components/oraganisms/home/MyModal";

export const Home = () => {
    const me = useSelector(((state) => state.user.me), shallowEqual);
    const [viewTestModal, setViewTestModal] = useState(false);
    const [viewUserModal, setViewUserModal] = useState(false);
    const [viewJoinModal, setViewJoinModal] = useState(false);
    const [viewMyModal, setViewMyModal] = useState(false);

    const onViewTestModal = useCallback(() => {
        setViewTestModal(true);
    }, []);

    const onViewUserModal = useCallback(() => {
        setViewUserModal(true);
    }, []);

    const onViewMyModal = useCallback(() => {
        setViewMyModal(true);
    }, []);

    return (
        <HomeWrap>
            <HomeHeader>
                <Button onClick={me.seq ? onViewMyModal : onViewUserModal}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-user">
                        <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2"></path>
                        <circle cx="12" cy="7" r="4"></circle>
                    </svg>
                </Button>
            </HomeHeader>

            <HomeStart>
                <Button onClick={onViewTestModal}>Pre Test</Button>
            </HomeStart>

            {
                viewTestModal && <PreTestModalItem setViewTestModal={setViewTestModal} />
            }

            {
                viewUserModal && <LoginModalItem setViewUserModal={setViewUserModal} setViewJoinModal={setViewJoinModal} />
            }

            {
                viewJoinModal && <JoinModalItem setViewJoinModal={setViewJoinModal} />
            }

            {
                viewMyModal && <MyModalItem setViewMyModal={setViewMyModal} me={me} />
            }
        </HomeWrap>
    )
}

const HomeWrap = styled.div`
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
`

const HomeHeader = styled.div`
  display: flex;
  font-size: 32px;
  padding: 20px;
  justify-content: end;

  & > button:last-child {
    color: #CC7EB2;
    font-weight: bold;
    background-color: #fff;
    padding: 10px;
    border-radius: 100%;
  }
`

const HomeStart = styled.div`
  position: absolute;
  top: 75%;
  left: 25vw;
  font-size: 32px;

  & > button:last-child {
    color: #fff;
    font-weight: bold;
    border: 3px solid #fff;
    padding: 20px 60px;
    border-radius: 60px;
  }
`

